import { Button, Checkbox, FormControlLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { useRecoilState } from "recoil";
import { TextInput } from "../../../../components";
import { useLabel, useLanguage } from "../../../../hooks";
import { APIStateContext } from "../../../../state/context";
import { ActiveProjectState, activeProjectState, useIsActiveProjectSelected } from "../../../../state/project";
import { Conversion, ConversionPoint, ProjectType } from "../../../../types/project";
import { removeItem, roundNumber } from "../../../../utils";
import generatePDF, { Margin } from "react-to-pdf";

function getIncludedPointNumbers(conversions: Array<Conversion> | undefined | null) {
    return conversions?.map((conversion: Conversion) => {
        return conversion.points.filter((point: ConversionPoint) => point.included).map(
            (point: ConversionPoint) => point.identifier
        );
    }) ?? [];
}
const Convert = (props: any) => {
    const activeProjectSelected = useIsActiveProjectSelected();
    const [activeProject, _] = useRecoilState(activeProjectState);

    const conversions = activeProject?.conversions
    const includedPointNumbers: Array<Array<string>> = getIncludedPointNumbers(conversions);
    const [pointNumbers, setPointNumbers] = useState(includedPointNumbers);
    
    if (!activeProjectSelected) {
        return <Placeholder label="labels.noActiveProjectSelected"/>
    } else if (activeProject.project?.project_type === ProjectType.LOCAL) {
        return <>
            <Subtitle labelKey="activeProject.convert" />
            <ConvertProject 
                activeProject={activeProject}
                conversions={conversions ?? []} 
                includedPointNumbers={pointNumbers}
                setPointNumbers={setPointNumbers}
            />
        </>
    } else {
        return <Placeholder label="labels.convertNotApplicable" />
    }
};

const Subtitle = (props: { labelKey: string }) => {
    const label = useLabel(props.labelKey);
    return <Typography variant="h4" style={{marginBottom: 20, marginTop: 30}}>{label}</Typography>
};

const Placeholder = (props: {label: string}) => {
    const label = useLabel(props.label)
    return <Paper elevation={3} sx={{ padding: 4, margin: 8 }}>
        <Typography>{label}</Typography>
    </Paper>
};

const ConvertProject = (props: {
    activeProject: ActiveProjectState,
    conversions: Array<Conversion>, 
    includedPointNumbers: Array<Array<string>>,
    setPointNumbers: any
}) => {
    const [tolerance, setTolerance] = useState(0.01);
    return <>
        <Controls 
            tolerance={tolerance} 
            setTolerance={setTolerance} 
            includedPointNumbers={props.includedPointNumbers}
            setPointNumbers={props.setPointNumbers}
            activeProject={props.activeProject}
        />
        <ConversionTables 
            id={"conversionTables"}
            conversions={props.conversions}
            tolerance={tolerance}
            includedPointNumbers={props.includedPointNumbers}
            setPointNumbers={props.setPointNumbers}
        />
    </>
};

const Controls = (props: {
    tolerance: number, 
    setTolerance: any, 
    includedPointNumbers: Array<Array<string>>,
    setPointNumbers: any,
    activeProject: ActiveProjectState
}) => {
    return <Box sx={{ flexDirection: "row", justifyContent: "space-between", display: "flex", alignItems: "center"}}>
        <ToleranceSelector tolerance={props.tolerance} setTolerance={props.setTolerance}/>
        <Stack direction="row" spacing={1}>
            <ExportPDFButton activeProject={props.activeProject} />
            <ExportKGSButton activeProject={props.activeProject} local/>
            <ExportKGSButton activeProject={props.activeProject} />
            <RecalculateButton 
                includedPointNumbers={props.includedPointNumbers} 
                setPointNumbers={props.setPointNumbers} 
                activeProject={props.activeProject}
            />
        </Stack>

    </Box>
};

const RecalculateButton = (props: {includedPointNumbers: any, setPointNumbers: any, activeProject: ActiveProjectState}) => {
    const label = useLabel("convert.recalculate");
    const api = useContext(APIStateContext);
    const recalculate = async () => {
        const conversions = await api.recalculateConversions(props.activeProject.project?.id, props.includedPointNumbers);
        if (conversions !== undefined) {
            props.setPointNumbers(getIncludedPointNumbers(conversions));
        }
    };
    return <Button variant="contained" onClick={recalculate}>{label}</Button>

};

const ToleranceSelector = (props: {tolerance: number, setTolerance: any}) => {
    const [value, setValue] = useState(props.tolerance.toString());
    
    return <Box>
        <TextInput 
            label={useLabel("convert.tolerance")}
            error={value !== props.tolerance.toString()}
            onChange={(event: any) => {
                setValue(event.target.value);
                if (!isNaN(event.target.value) && (!isNaN(parseFloat(event.target.value)))) {
                    props.setTolerance(parseFloat(event.target.value));
                }
            }}
            value={value}
            type="number"
        />
    </Box>
};

const ConversionTables = (props: {
    id: string
    conversions: Array<Conversion>, 
    tolerance: number, 
    includedPointNumbers: Array<Array<string>>,
    setPointNumbers: any
}) => {
    const language = useLanguage();
    return <div id={props.id}>
        {props.conversions.filter((c: Conversion) => c.points.length > 0).map((conversion: Conversion, index: number) => 
            <Stack direction={"column"} spacing={2} key={index.toString()}>
                {
                    conversion.local ? <Typography variant="h4">{language.convert.origin(conversion.origin_point_number)}</Typography> : 
                    <Typography variant="h4">{language.convert.gps}</Typography>
                }
                <ConversionTable
                    conversion={conversion} 
                    tolerance={props.tolerance} 
                    includedPointNumbers={props.includedPointNumbers}
                    conversionIndex={index}
                    setPointNumbers={props.setPointNumbers}
                />
            </Stack>
        )}
    </div>
};

const ConversionTable = (props: {
    conversion: Conversion, 
    tolerance: number, 
    includedPointNumbers: Array<Array<string>>
    conversionIndex: number,
    setPointNumbers: any
}) => {
    const convertLanguage = useLanguage().convert;

    const points = props.conversion.points.toSorted((a: ConversionPoint, b: ConversionPoint) => (a.identifier < b.identifier ? -1 : 1))
    return <TableContainer sx={{mt: 2}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{convertLanguage.usePoint}</TableCell>
                    <TableCell>{convertLanguage.localPoint}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{convertLanguage.GPSPoint}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{convertLanguage.calculatedPoint}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{convertLanguage.deviation}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {points.map((point: ConversionPoint) => 
                    <ConversionPointRow 
                        key={point.identifier} 
                        point={point} 
                        tolerance={props.tolerance} 
                        includedPointNumbers={props.includedPointNumbers}
                        conversionIndex={props.conversionIndex}
                        setPointNumbers={props.setPointNumbers}
                    />)}
            </TableBody>
        </Table>
    </TableContainer>
};

export function setCheckedNumber(index: number, pointNumber: string, includedPointNumbers: Array<Array<string>>): Array<Array<string>> {
    var newIncludedPointNumbers: Array<Array<string>> = [];
    for (let i = 0; i < includedPointNumbers.length; i++) {
        if (i !== index) {
            newIncludedPointNumbers = newIncludedPointNumbers.concat([includedPointNumbers[i]])
        } else {
            if (! includedPointNumbers[i].includes(pointNumber)) {
                newIncludedPointNumbers = newIncludedPointNumbers.concat([includedPointNumbers[i].concat([pointNumber])])
            } else {
                newIncludedPointNumbers = newIncludedPointNumbers.concat([includedPointNumbers[i]])
            }
        }
    }
    return newIncludedPointNumbers;
};

export function setUncheckedNumber(index: number, pointNumber: string, includedPointNumbers: Array<Array<string>>) {
    var newIncludedPointNumbers: Array<Array<String>> = [];
    for (let i = 0; i < includedPointNumbers.length; i++) {
        if (i !== index) {
            newIncludedPointNumbers = newIncludedPointNumbers.concat([includedPointNumbers[i]])
        } else {
            const current = removeItem(includedPointNumbers[i], pointNumber);
            newIncludedPointNumbers = newIncludedPointNumbers.concat([current]);
        }
    }
    return newIncludedPointNumbers;
}


export const IncludeCheckbox = (props: {
    index: number, 
    pointNumber: string, 
    includedPointNumbers: Array<Array<string>>
    setPointNumbers: any
}) => {
    function setChecked() {
        props.setPointNumbers(setCheckedNumber(props.index, props.pointNumber, props.includedPointNumbers));
    };

    function setUnchecked() {
        props.setPointNumbers(setUncheckedNumber(props.index, props.pointNumber, props.includedPointNumbers));
    };


    const onChange = (event: any) => {
        if (event.target.checked) {
            setChecked();
        } else {
            setUnchecked();
        }
    }

    return <FormControlLabel 
        label={""}
        control={<Checkbox
            disabled={false}
            checked={props.includedPointNumbers[props.index].includes(props.pointNumber)}
            onChange={(event: any) => onChange(event)}
        />}
    />
}

const ConversionPointRow = (props: {
    point: ConversionPoint, 
    tolerance: number, 
    includedPointNumbers: Array<Array<string>>,
    conversionIndex: number,
    setPointNumbers: any
}) => {
    const labelPointNumber = useLabel("convert.pointNumber");
    const labelDeviation = useLabel("convert.deviation");
    
    const localX = roundNumber(props.point.local_coordinate.x, 3);
    const localY = roundNumber(props.point.local_coordinate.y, 3);
    const localZ = roundNumber(props.point.local_coordinate.z, 3);
    const gpsX = roundNumber(props.point.expected_coordinate.x, 3);
    const gpsY = roundNumber(props.point.expected_coordinate.y, 3);
    const gpsZ = roundNumber(props.point.expected_coordinate.z, 3);
    const calculatedX = roundNumber(props.point.calculated_coordinate.x, 3);
    const calculatedY = roundNumber(props.point.calculated_coordinate.y, 3);
    const calculatedZ = roundNumber(props.point.calculated_coordinate.z, 3);
    
    const deviationX = roundNumber(Math.abs(calculatedX - gpsX), 3);
    const deviationY = roundNumber(Math.abs(calculatedY - gpsY), 3);
    const deviationZ = roundNumber(Math.abs(calculatedZ - gpsZ), 3);

    const colorX = deviationX > props.tolerance ? "red" : "black"
    const colorY = deviationY > props.tolerance ? "red" : "black"
    const colorZ = deviationZ > props.tolerance ? "red" : "black"
    
    return <>
        <TableRow>
            <TableCell>
                <IncludeCheckbox 
                    pointNumber={props.point.identifier}
                    setPointNumbers={props.setPointNumbers}
                    includedPointNumbers={props.includedPointNumbers}
                    index={props.conversionIndex}
                />
                
            </TableCell>
            <TableCell>{labelPointNumber}</TableCell>
            <TableCell>{props.point.identifier}</TableCell>
            <TableCell>{labelPointNumber}</TableCell>
            <TableCell>{props.point.identifier}</TableCell>
            <TableCell>{labelPointNumber}</TableCell>
            <TableCell>{props.point.identifier}</TableCell>
            <TableCell>{labelDeviation}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell></TableCell>
            <TableCell>X</TableCell>
            <TableCell>{localX}</TableCell>
            <TableCell>X</TableCell>
            <TableCell>{gpsX}</TableCell>
            <TableCell>X</TableCell>
            <TableCell>{calculatedX}</TableCell>
            <TableCell><Typography color={colorX}>{deviationX}</Typography></TableCell>
        </TableRow>
        <TableRow>
            <TableCell></TableCell>
            <TableCell>Y</TableCell>
            <TableCell>{localY}</TableCell>
            <TableCell>Y</TableCell>
            <TableCell>{gpsY}</TableCell>
            <TableCell>Y</TableCell>
            <TableCell>{calculatedY}</TableCell>
            <TableCell><Typography color={colorY}>{deviationY}</Typography></TableCell>
        </TableRow>
        <TableRow>
            <TableCell></TableCell>
            <TableCell>Z</TableCell>
            <TableCell>{localZ}</TableCell>
            <TableCell>Z</TableCell>
            <TableCell>{gpsZ}</TableCell>
            <TableCell>Z</TableCell>
            <TableCell>{calculatedZ}</TableCell>
            <TableCell><Typography color={colorZ}>{deviationZ}</Typography></TableCell>
        </TableRow>
    </>
}

function ExportKGSButton(props: {activeProject: ActiveProjectState, local?: boolean}) {
    const basePoints = props.activeProject.base_points;
    const label = useLabel(`convert.${props.local ? "exportKGSLocal" : "exportKGSGPS"}`)

    let disabled = false;
    if (!props.local && (!basePoints || (basePoints[0].gps_coordinate === null))) {
        disabled = true;
    };

    function exportKGS() {
        let csv = (basePoints ?? []).map((point) => {
            if (props.local) {
                return `"99","${point.point_number}",${point.local_coordinate.x},${point.local_coordinate.y},${point.local_coordinate.z}`
            } else {
                return `"99","${point.point_number}",${point.gps_coordinate.x},${point.gps_coordinate.y},${point.gps_coordinate.z}`
            }
        }).join("\n");
        const file = new Blob([csv], { type: 'text/plain' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = (props.activeProject.project?.name ?? "export") + ".kgs";
        link.click();
    }

    return <Button variant="contained" onClick={exportKGS} disabled={disabled} >
        {label}
    </Button>
}


function ExportPDFButton(props: {activeProject: ActiveProjectState, local?: boolean}) {
    const label = useLabel("convert.exportPDF")

    function exportPDF() {
    
        const tableHTML = () => document.getElementById('conversionTables');
        generatePDF(tableHTML, {
            filename: (props.activeProject.project?.name ?? "export") + ".pdf",
            page: {
                margin: Margin.MEDIUM,
                format: "A4",
                orientation: "portrait"
            }
        })

    }

    return <Button variant="contained" onClick={exportPDF}>
        {label}
    </Button>
}

export default Convert;
