import { Table, TableHead, TableCell, TableContainer, TableRow, TableBody, TextField, IconButton, Menu, MenuItem, styled, Tooltip, Icon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { useLabel, useLanguage } from "../../hooks";
import { EmptyRecord } from "../../screens/dashboard/panels/modify-data/changes";
import { Add } from "@mui/icons-material";

interface Header<Record extends EmptyRecord> {
    name: string
    field: keyof Record
};

class DataSet<Record extends EmptyRecord> {
    records: Array<Record>;

    constructor(records: Array<Record>) {
        this.records = records;
    };

    insert(insert: Insert<Record>) {
        if (insert.after_record === null || insert.after_record === undefined) {
            this.records = [insert.record, ...this.records];
        } else {
            const index = this.records.indexOf(insert.after_record);
            this.records = [...this.records.slice(0, index + 1), insert.record, ...this.records.slice(index + 1)]
        }
        return this;
    };
    update(update: Update<Record>) {
        const index = this.records.indexOf(update.old_record);
        this.records = [...this.records.slice(0, index), update.record, ...this.records.slice(index + 1)]
        return this;
    };
    delete(deleted: Delete<Record>) {
        const index = this.records.indexOf(deleted.old_record);
        this.records = [...this.records.slice(0, index), ...this.records.slice(index + 1)]
        return this
    };
};

enum ChangeType {
    INSERT = "INSERT",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
};

interface DataSetChange<Record extends EmptyRecord> {
    change_type: ChangeType
    record?: Record | null
    old_record?: Record | null
    after_record?: Record | null
};

interface Insert<Record extends EmptyRecord> extends DataSetChange<Record> {
    after_record: Record
    record: Record
};

interface Update<Record extends EmptyRecord> extends DataSetChange<Record> {
    old_record: Record,
    record: Record
};

interface Delete<Record extends EmptyRecord> extends DataSetChange<Record> {
    old_record: Record
};

interface InsertRecordFunc<Record extends EmptyRecord> {
    (insert: Insert<Record>): DataSet<Record>
};

interface UpdateRecordFunc<Record extends EmptyRecord> {
    (update: Update<Record>): DataSet<Record>
};

interface DeleteRecordFunc<Record extends EmptyRecord> {
    (deleted: Delete<Record>): DataSet<Record>
};

interface DataTableProps<Record extends EmptyRecord> {
    data: DataSet<Record>
    headers: Array<Header<Record>>,
    pageNumber: number,
    pageSize: number,
    insertRecord: InsertRecordFunc<Record>,
    updateRecord: UpdateRecordFunc<Record>,
    deleteRecord: DeleteRecordFunc<Record>,
};

const CellInput = styled(TextField)`
    fieldset {
        border-radius: 0px;
    }
`;

function Cell<Record extends EmptyRecord>(props: {
    record: Record,
    field: keyof Record,
    updateRecord: UpdateRecordFunc<Record>,
}) {
    // const [originalValue, _] = useState(props.record[props.field]);
    const [tempValue, setTempValue] = useState(props.record[props.field]);
    var errorKey = "";
    var error = "";
    if ("error" in props.record) {
        errorKey = props.record?.error?.label ?? "";
    };
    const errorData = props.record?.error?.data as any;
    const errorFunc = (useLanguage().error.server as any)[errorKey];
    if (errorData !== undefined && errorFunc !== undefined) {
        error = errorFunc(errorData);
    }

    var sx;
    if (errorKey !== "") {
        sx = {
            padding: 0,
            borderColor: "red",
            borderWidth: 1,
            borderStyle: "solid",
            width: "100%",
        }
    } else {
        sx = {padding: 0}
    }
    const cell = <TableCell sx={sx}>
        <CellInput
            // label={originalValue !== tempValue ? "modified" : undefined}
            fullWidth
            value={tempValue ?? ""}
            onChange={(event: any) => setTempValue(event.target.value)}
            onBlur={() => {
                if (tempValue !== props.record[props.field]) {
                    props.updateRecord({
                        change_type: ChangeType.UPDATE,
                        old_record: props.record,
                        record: {...props.record, [props.field]: tempValue}
                    })
                }
            }}
            type={typeof tempValue === "number" ? "number" : "string"}
        />
    </TableCell>
    if (errorKey !== null) {
        return <Tooltip title={error}>{cell}</Tooltip>;
    } else {
        return cell;
    }
}

function MenuCell<Record extends EmptyRecord>(props: {record: Record, insertRecord: InsertRecordFunc<Record>, deleteRecord: DeleteRecordFunc<Record>}) {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchor);
    const handleClick = (event: any) => setAnchor(event.currentTarget);
    const handleClose = (_: any) => setAnchor(null);

    const insertLabel = useLabel("modifyData.insert");
    const deleteLabel = useLabel("modifyData.delete");

    return <TableCell sx={{ width: 12 }}>
        <IconButton onClick={handleClick}  sx={{ padding: 0 }}>
            <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchor} open={open} onClose={handleClose}>
            <MenuItem key="insert" onClick={(_: any) => {props.insertRecord({
                change_type: ChangeType.INSERT,
                after_record: props.record,
                record: {...props.record, id: props.record.id + Date.now().toString()}
            })}}>
                {insertLabel}
            </MenuItem>
            <MenuItem key="delete" onClick={(_: any) => {props.deleteRecord({
                change_type: ChangeType.DELETE,
                old_record: props.record,
            })}}>
                {deleteLabel}
            </MenuItem>
        </Menu>
    </TableCell>
};

function Row<Record extends EmptyRecord>(props: {
    record: Record, 
    headers: Array<Header<Record>>,
    insertRecord: InsertRecordFunc<Record>,
    updateRecord: UpdateRecordFunc<Record>,
    deleteRecord: DeleteRecordFunc<Record>
}) {
    return <TableRow>
        <MenuCell record={props.record} insertRecord={props.insertRecord} deleteRecord={props.deleteRecord}/>
        {props.headers.map((header: Header<Record>) => <Cell key={props.record.id + "-" + header.field.toString()} record={props.record} field={header.field} updateRecord={props.updateRecord} />)}
    </TableRow>
};

function DataTable<Record extends EmptyRecord> (props: DataTableProps<Record>) {
    const pageRecords = props.data.records.slice(props.pageSize * props.pageNumber, props.pageSize * (props.pageNumber + 1))
    return <TableContainer sx={{ overflowX: "scroll", maxWidth: "100%", display: "block"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell key="empty"></TableCell>
                    {props.headers.map((header: Header<Record>) => <TableCell key={"header-" + header.field.toString()} >{header.name}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {pageRecords.map((record: Record) => {
                    return <Row 
                        key={"row" + record.id} 
                        record={record} 
                        headers={props.headers}
                        insertRecord={props.insertRecord}
                        updateRecord={props.updateRecord}
                        deleteRecord={props.deleteRecord}
                    />
                }
                )}
            </TableBody>
        </Table>
    </TableContainer>
};

export default DataTable;
export { DataSet };
export type { DataSetChange, Header };