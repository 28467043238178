import { hash, sleep } from "../../utils";
import client from "../../api";
import { ProjectFileUploadURLRequest  } from "../../api/requests/project";
import { ProjectFileUploadURLResponse } from "../../api/responses/project";
import { SetFileStatusFunc } from "./types";
import { FileStatus } from "../../types/project";


export async function readFile(file: File) {
    var content: string | undefined | null | ArrayBuffer = "DUMMY";

    const reader = new FileReader();
    reader.onload = (e) => {
        content = e.target?.result;
    };

    reader.readAsText(file)
    for (let i = 0; i < 5; i++) {
        if (content === "DUMMY") {

            await sleep(1000);
        };
    };

    return content
}

export async function handleFileUpload(
    companyID: string | undefined, 
    setFile: any, 
    files: FileList | null,
    setFileStatus: SetFileStatusFunc
) {
    if ((files === null) || (files.length === 0) || (companyID === null)) {
        return;
    };
    setFileStatus(FileStatus.UPLOADING);
    setFile({
        name: files[0].name,
        hash: "",
        url: "",
        status: FileStatus.UPLOADING
    });
    const content = await readFile(files[0]);
    const fileHash = await hash(content);
    const request: ProjectFileUploadURLRequest = {
        company_id: companyID ?? "",
        file_hash: fileHash
    };

    try {
        const response: ProjectFileUploadURLResponse = await client.getProjectFileUploadURL(request)
        
        await client.uploadS3File(files[0], response, content);
        setFile({
            name: files[0].name,
            hash: fileHash,
            url: "",
            status: FileStatus.UPLOADED
        });
        setFileStatus(FileStatus.UPLOADED);
    } catch {
        setFileStatus(FileStatus.FAILED);
    }
};
