import { useState } from 'react';
import { Box, Container, Button, Typography } from "@mui/material";
import { TextInput, LogoLarge } from "../../../components";
import { useLabel, useLanguage } from '../../../hooks';
import client from '../../../api';
import { ForgotPasswordRequest } from '../../../api/requests/user';
import { ForgotPasswordResponse } from '../../../api/responses/user';
import { Link } from 'react-router-dom';

const styles = {
    logoBox: {
        justifyContent: "center",
        display: "flex",
        paddingBottom: 6
    }
}

const Main = (props: any) => {
    const { children } = props;
    return <Box 
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            minHeight: '100vh',
            justifyContent: "center"
        }}>
        {children}
    </Box>
}

const Header = () => {
    return <>
        <Typography
            color="textPrimary"
            variant="h4"
        >
            {useLabel("forgotPassword.label")}
        </Typography>
    </>
}

const Logo = () => {
    return <Link to="/">
        <Box sx={styles.logoBox}><Box><LogoLarge width={180} height={150}/></Box>
        </Box>
    </Link>
}

const EmailInput = (props: {email: string, setEmail: any}) => {
    return <TextInput
        id="email-input"
        label={useLabel("labels.email")}
        error={false}
        onChange={(event: any) => props.setEmail(event.target.value)}
        value={props.email}
        type="email"
    />
}

const Feedback = (props: {label: (email: string) => string, email: string}) => {
    return <Typography>{props.label(props.email)}</Typography>
}

const SubmitButton = (props: {onSubmit: any, waiting: boolean}) => {

    return <Box sx={{ py: 2 }}>
        <Button
            id="login-button"
            color="primary"
            disabled={props.waiting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={props.onSubmit}
        >
        {useLabel("forgotPassword.label")}
        </Button>
    </Box>
}

async function handleSubmit(
    email: string, 
    setWaiting: any,
    setDone: any,

) {
    setWaiting(true);
    const request: ForgotPasswordRequest = {
        email: email
    };
    try {
        await client.forgotPassword(request).then((response: ForgotPasswordResponse) => {
            setWaiting(false);
            setDone(true);
        })
    } catch(error) {
        alert(error);
        console.log(error);
    } finally {
        setWaiting(false);
    }
}

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [done, setDone] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const feedbackLabel = useLanguage().forgotPassword.feedback;
    const onSubmit = () => handleSubmit(
        email, 
        setWaiting,
        setDone
    );

    
    return <Main>
        <Container maxWidth="sm">
            <Logo />
            <Header />
            {done ? <Feedback label={feedbackLabel} email={email}/> : <>
                <EmailInput email={email} setEmail={setEmail} />
                <SubmitButton onSubmit={onSubmit} waiting={waiting}/>
            </>}
        </Container>
    </Main>
};

export default ForgotPassword;
