import React from "react";
import logoLargeImg from "../../img/plan2LogoLarge.jpg";

const LogoLarge = (props: {width: number, height: number}) => <img 
    alt="Plan2 Logo"
    src={logoLargeImg} 
    style={{ 
        flex: 1,
        height: props.height,
        width: props.width
     }}/>

export default LogoLarge;