import { ParseRecord, ProjectFileLine, RawProjectRecord } from "../../../../types/project";

export function getFileLines(page: Array<ParseRecord>): Array<ProjectFileLine> | undefined {
    if (page) {
        return page.map((parseRecord: ParseRecord) => {
            return {
                ...parseRecord.line, 
                error: {
                    label: parseRecord.error?.label ?? parseRecord.error?.error_code ?? "",
                    data: parseRecord.error?.data ?? parseRecord.error?.error_params ?? {}
                }
            }
        })
    }
}

export function getRawRecords(page: Array<ParseRecord>): Array<RawProjectRecord> | undefined {
    if (page) {
        return page.map(mapToRawRecord)
    }
}

function mapToRawRecord(parseRecord: ParseRecord): RawProjectRecord {
    const rawRecord = parseRecord.raw_record as RawProjectRecord;

    return {
        ...rawRecord,
        error: {
            label: parseRecord.error?.label ?? parseRecord.error?.error_code ?? "",
            data: parseRecord.error?.data ?? parseRecord.error?.error_params ?? {}
        }}
}