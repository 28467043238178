import AppInfoResponse from "../api/responses/appInfo";
import LoginResponse from "../api/responses/login";
import { CreateProjectResponse, GetProjectsResponse, LoadActiveProjectResponse, ProjectFileUploadURLResponse } from "../api/responses/project";
import { GetProfilesResponse } from "../api/responses/profile";
import { Role } from "../types/auth";
import { ProjectType } from "../types/project";
import { defaultDrawSettings } from "./defaultValues";
import { ParseErrorType } from "../api/errors/errors";

export const mockLoginResponse: LoginResponse = {
    token: "eyJhbGciOiJIUzI1NiJ9.eyJjb21wYW55SUQiOiIxMjM0NTY3ODkiLCJ1c2VySUQiOiIxMjM0NTY3ODkiLCJyb2xlIjoiQ09NUEFOWV9BRE1JTiIsImV4cGlyYXRpb24iOjQxMDI0NDEyMDB9.lNgXccf6OSaEDi41LepylX1ITRoeT5NZy3Ka16w_o7I",
    user: {
        name: "Aron Geerts",
        id: "123456789",
        role: Role.appAdmin
    },
    company: {
        name: "Aron's Test company",
        id: "123456789"
    },
    expiration: 4102441200000
};

export const mockAppInfoResponse: AppInfoResponse = {
    author: "Aron Geerts",
    version: "1.2.3",
    contact: "arongeerts8@gmail.com"
};

export const mockProjectFileUploadResponse: ProjectFileUploadURLResponse = {
    url: "https://some-fake-url/",
    fields:{
        key: "",
        AWSAccessKeyID: "",
        policy: "",
        signature: ""
    }
};

export const mockGetProfilesResponse: GetProfilesResponse = {
    profiles: [
        {
            name: "GRB Bijhouding",
            id: "123",
            object_code_length: 3,
            symbols_file: {
                name: "GRB.dwg",
                download_url: "http://fake-url/",
                id: "123456789",
            },
            creation_timestamp: 1649688151777,
            update_timestamp: 1649688151777,
            created_by: "123456789"
        },
        {
            name: "Plan Privé",
            id: "456",
            object_code_length: 2,
            symbols_file: {
                name: "Plan Prive.dwg",
                download_url: "http://fake-url/",
                id: "123456789"
            },
            creation_timestamp: 1649688151777,
            update_timestamp: 1649688151777,
            created_by: "123456789"
        },
        {
            name: "GRB 2017",
            id: "789",
            object_code_length: 3,
            symbols_file: {
                name: "GRB 2017.dwg",
                download_url: "http://fake-url/",
                id: "123456789"
            },
            creation_timestamp: 1649688151777,
            update_timestamp: 1649688151777,
            created_by: "123456789"
        }
    ]
};

export const mockCreateProjectResponse: CreateProjectResponse = {
    project: {
        id: "ABC",
        company_id: "C1",
        name: "Example Project",
        project_type: ProjectType.GPS,
        draw_settings: defaultDrawSettings,
        csv_file: {
            name: "20220101.csv",
            id: "abcdef012345677890",
            url: "https://fake-download/",
            upload_timestamp: 1649688151777,
            uploaded_by: "123456789"
        },
        gps_file: null,
        tmf_file: null,
        profile_id: "456",
        creation_timestamp: 1649688151777,
        update_timestamp: 1649688151777,
        created_by: "123456789",
        updated_by: "123456789"
    }
};

export const mockGetProjectsResponse: GetProjectsResponse = {
    projects: [
        {
            id: "ABC",
            company_id: "C1",
            name: "CSV Project",
            project_type: ProjectType.GPS,
            draw_settings: defaultDrawSettings,
            csv_file: {
                name: "20220101.csv",
                id: "abcdef012345677890",
                url: "https://fake-download/",
                upload_timestamp: 1649688151777,
                uploaded_by: "123456789"
            },
            gps_file: null,
            tmf_file: null,
            profile_id: "456",
            creation_timestamp: 1649688151777,
            update_timestamp: 1649688151777,
            created_by: "123456789",
            updated_by: "123456789"
        },
        {
            id: "DEF",
            company_id: "C1",
            name: "TMF Project no GPS",
            project_type: ProjectType.LOCAL,
            draw_settings: defaultDrawSettings,
            csv_file: null,
            gps_file: null,
            tmf_file: {
                name: "20220101.tmf",
                id: "abcdef012345677890",
                url: "https://fake-download/",
                upload_timestamp: 1649688151777,
                uploaded_by: "123456789"
            },
            profile_id: "456",
            creation_timestamp: 1649688151777,
            update_timestamp: 1649688151777,
            created_by: "123456789",
            updated_by: "123456789"
        },
        {
            id: "GHI",
            company_id: "C1",
            name: "TMF Project with GPS",
            project_type: ProjectType.LOCAL,
            draw_settings: defaultDrawSettings,
            csv_file: null,
            gps_file: {
                name: "20220101.gps",
                id: "abcdef012345677890",
                url: "https://fake-download/",
                upload_timestamp: 1649688151777,
                uploaded_by: "123456789"
            },
            tmf_file: {
                name: "20220101.tmf",
                id: "abcdef012345677890",
                url: "https://fake-download/",
                upload_timestamp: 1649688151777,
                uploaded_by: "123456789"
            },
            profile_id: "456",
            creation_timestamp: 1649688151777,
            update_timestamp: 1649688151777,
            created_by: "123456789",
            updated_by: "123456789"
        }
    ]
};

export const mockGetProfileRecordsResponse = {
    records: Array.from(Array(100).keys()).map((i: number) =>
        {
            return {
                id: i.toString(),
                code: "01",
                layer: "layer" + i,
                lineType: "dashed",
                color: "blue",
                symbolName: "0" + i,
                orientation: 0,
                scale: 0,
                description: "Rand van de weg"
            }
        }
    )
};

export const mockUpdateProfileRecordsResponse = mockGetProfileRecordsResponse;

export const mockRawRecords = [
    {
        id: "1",
        objectCode: "12",
        coordinateX: "13.456789",
        coordinateY: "156.1216",
        coordinateZ: "11.1235640",
        info: "12p",
        error: {
            label: "errors.server.parsing.invalidNbFields",
            data: ["6", "5"]
        }
    },
    {
        id: "2",
        objectCode: "12",
        coordinateX: "13.456789",
        coordinateY: "156.1216",
        coordinateZ: "11.1235640",
        info: "12p",
        error: {
            label: "errors.server.parsing.invalidNbFields",
            data: ["6", "5"]
        }
    },
    {
        id: "3",
        objectCode: "12",
        coordinateX: "13.456789",
        coordinateY: "156.1216",
        coordinateZ: "11.1235640",
        info: "12p",
        error: {
            label: "errors.server.parsing.invalidNbFields",
            data: ["6", "5"]
        }
    },
    {
        id: "4",
        objectCode: "12",
        coordinateX: "13.456789",
        coordinateY: "156.1216",
        coordinateZ: "11.1235640",
        info: "12p",
        error: {
            label: "errors.server.parsing.invalidNbFields",
            data: ["6", "5"]
        }
    },
    {
        id: "5",
        objectCode: "12",
        coordinateX: "13.456789",
        coordinateY: "156.1216",
        coordinateZ: "11.1235640",
        info: "12p",
        error: {
            label: "errors.server.parsing.invalidNbFields",
            data: ["6", "5"]
        }
    },

]
export const mockInvalidFileFormat = {
    project: mockGetProjectsResponse.projects[0],
    parseResult: {
        parseError: ParseErrorType.INVALID_FILE,
        nbRecords: 500,
        nbErrors: 5,
        page: mockRawRecords
    }
};

export const mockProjectRecords = [
    {
        id: "1",
        error: {
            label: "errors.server.parsing.invalidNbFields",
            data: ["6", "5"]
        },
        identificationCode: "4",
        objectCode: "66",
        lineCode: 2,
        lineNumber: 1,
        excentricity: "p",
        setupHeight: 2,
        coordinate: {
            x: 100,
            y: 200.135,
            z: 100
        },
        pointNumber: "1000",
        attributes: "",
        sequenceNumber: 1
    }
]

export const mockParsingFailed = {
    project: mockGetProjectsResponse.projects[0],
    parseResult: {
        parseError: ParseErrorType.PARSING_FAILED,
        nbRecords: 500,
        nbErrors: 1,
        page: mockProjectRecords
    }
};

export const mockLoadProjectResponse: LoadActiveProjectResponse = {
    project: mockGetProjectsResponse.projects[0],
    parse_result: {
        nb_records: 500,
        nb_errors: 1,
        page: [
            {
                line: {
                    id: "1",
                    line: "some,line,content"
                },
                error: {
                    label: "errors.server.parsing.invalidNbFields",
                    data: {line: "6", expected: "5"}
                },
                raw_record: {
                    id: "2",
                    coordinate_x: "100",
                    coordinate_y: "100",
                    coordinate_z: "100",
                    point_number: "1000",
                    info: "",
                },
                record: null

            }
        ]
    },
    conversions: [{
        origin_point_number: "100",
        local: true,
        points: [{
            included: true,
            identifier: "100",
            local_coordinate: {
                x: -0.01,
                y: 77.70,
                z: -0.06
            },
            expected_coordinate: {
                x: 153456.10,
                y: 156123.130,
                z: 21.12
            },
            calculated_coordinate: {
                x: 153456.12,
                y: 156123.430,
                z: 21.22
            }
        },{
            included: true,
            identifier: "101",
            local_coordinate: {
                x: -0.01,
                y: 77.70,
                z: -0.06
            },
            expected_coordinate: {
                x: 153456.10,
                y: 156123.130,
                z: 21.12
            },
            calculated_coordinate: {
                x: 153456.12,
                y: 156123.430,
                z: 21.22
            }
        },{
            included: true,
            identifier: "102",
            local_coordinate: {
                x: -0.01,
                y: 77.70,
                z: -0.06
            },
            expected_coordinate: {
                x: 153456.10,
                y: 156123.130,
                z: 21.12
            },
            calculated_coordinate: {
                x: 153456.12,
                y: 156123.430,
                z: 21.22
            }
        }],
        helmert: {
            a: 1.50,
            b: 2.456,
            c: 864.21,
            d: -1.02,
            dz: 12
        }
    }]
};

export const mockDrawResponse = {
    company_id: "C1",
    project_id: "P1",
};

export const mockGetDrawingStatus = {
    status: "COMPLETED",
    dxf: {
        name: "dxf_drawing.dxf",
        url: "http://localhost:3000/210730sv.DXF"
    },
    dwg: {
        name: "dwg_drawing.dwg",
        url: "http://localhost:3000/index.html"
    },
    error: null
};

export const mockUser = {
    id: "U1",
    name: "Test User",
    email: "user@test.com",
    company_id: "C1",
    role: Role.companyUser,
    creation_timestamp: 1649688151777
};