import { Api } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { PaperContainer, S3FileUpload, TextInput } from "../../../../../components";
import { useLabel } from "../../../../../hooks";
import { APIStateContext } from "../../../../../state/context";
import { ProfileHeader, SymbolsFileInput } from "../../../../../types/profile";
import { FileStatus, SelectedFile } from "../../../../../types/project";
import { Subtitle } from "./common";

const SaveButton = (props: {save: any}) => {
    const buttonLabel = useLabel("labels.save");
    const [disabled, setDisabled] = useState(false);
    return <Box sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        flexDirection: "row",
        mt: 4  
    }}>
        <Box sx={{ minWidth: "100px" }}>
            <Button 
                disabled={disabled}
                onClick={async () => {setDisabled(true); await props.save(); setDisabled(false);}}
                variant="contained"
            >{buttonLabel}</Button>
        </Box>
    </Box>
}
const EditProfileHeader = (props: {profile: ProfileHeader}) => {
    const [name, setName] = useState(props.profile.name);
    const [objectCodeLength, setObjectCodeLength] = useState(props.profile.object_code_length);
    const selectedSymbolsFile: SelectedFile = {
        hash: props.profile.symbols_file.id,
        name: props.profile.symbols_file.name,
        url: "",
        status: FileStatus.UPLOADED,
    }
    const [symbolsFile, setSymbolsFile] = useState(selectedSymbolsFile);

    const selectedObjFile: SelectedFile = {
        hash: "",
        name: "",
        url: "",
        status: FileStatus.UPLOADING,
    };
    const [objFile, setObjFile] = useState(selectedObjFile);

    const api = useContext(APIStateContext);
    console.log(objFile)

    const update = async () => {
        const newSymbolsFile: SymbolsFileInput = {
            id: symbolsFile.hash,
            name: symbolsFile.name
        };
        return await api.updateProfileHeader(
            props.profile.id,
            name,
            objectCodeLength,
            newSymbolsFile,
            objFile.hash === "" ? undefined : objFile
        );
    };

    return <PaperContainer fill={false}>
        <Subtitle labelKey={"profileConfig.editProfileHeader"} />
        <TextInput
            label={useLabel("profileConfig.name")}
            error={false}
            onChange={(event: any) => setName(event.target.value)}
            value={name}
            type="string"
        />
        <TextInput
            label={useLabel("profileConfig.objectCodeLength")}
            error={false}
            onChange={(event: any) => setObjectCodeLength(event.target.value)}
            value={objectCodeLength.toString()}
            type="number"
        />
        <Subtitle labelKey="existingProfiles.symbolsFileName" />
        <S3FileUpload
            labelKey="profileConfig.uploadSymbols"
            extensions={[".dwg"]}
            file={symbolsFile}
            setFile={setSymbolsFile}
        />
        <S3FileUpload
            labelKey="profileConfig.uploadObj"
            extensions={[".obj"]}
            file={objFile}
            setFile={setObjFile}
        />
        <SaveButton save={() => update()} />
    </PaperContainer>
};

export default EditProfileHeader;