import { Box, Button, FormControl, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { TextInput } from '../../components';
import { useLabel } from '../../hooks';
import { APIStateContext } from '../../state/context';

const PasswordInput = (props: {password: string, setPassword: Dispatch<SetStateAction<string>>, confirmPassword: string}) => {
    const areEqualAndFilled = props.password === props.confirmPassword || props.confirmPassword === "";
    const passwordIsLongEnough = props.password === "" || props.password.length >= 8;
    const isValid = areEqualAndFilled && passwordIsLongEnough

    const passwordTooShort = useLabel("userLabels.passwordShort");
    const passwordsDontMatch = useLabel("userLabels.passwordsDontMatch")
    
    var helperText = "";
    if (isValid) {
        helperText = "";
    } else if (!passwordIsLongEnough) {
        helperText = passwordTooShort;
    } else if (!areEqualAndFilled) {
        helperText = passwordsDontMatch;
    }

    return <TextInput 
        label={useLabel("userLabels.newPassword")} 
        value={props.password} 
        onChange={(event: any) => props.setPassword(event.target.value)}
        type="password"
        error={!isValid}
        helperText={helperText}
    />
};

const ConfirmPasswordInput = (props: {confirmPassword: string, setConfirmPassword: Dispatch<SetStateAction<string>>, password: string}) => {
    return <TextInput 
        label={useLabel("labels.confirmPassword")} 
        value={props.confirmPassword} 
        onChange={(event: any) => props.setConfirmPassword(event.target.value)}
        type="password"
        error={props.password !== props.confirmPassword && props.confirmPassword !== ""}
    />
};

const UpdatePasswordButton = (props: {password: string, confirmPassword: string}) => {
    const label = useLabel("userLabels.changePassword")
    const api = useContext(APIStateContext);

    const update = async () => {
        await api.updateUserPassword(props.password)
    };

    const isValid = (
        props.password === props.confirmPassword && props.password.length >= 8
    );

    return <Box mt={4}><Button onClick={update} variant="contained" disabled={!isValid}>{label}</Button></Box>
};

const ChangePassword = (props: any) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    return <Box m={4}>
        <Typography variant="h4">{useLabel("userLabels.changePassword")}</Typography>
        <PasswordInput password={password} setPassword={setPassword} confirmPassword={confirmPassword}/>
        <ConfirmPasswordInput confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} password={password}/>
        <UpdatePasswordButton 
            password={password} 
            confirmPassword={confirmPassword} 
        />
    </Box>
};

export default ChangePassword;