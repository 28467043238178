import { useEffect } from "react";
import { Box, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { DxfViewer } from "dxf-viewer";
import { useLabel } from "../../../../hooks";
import CloseIcon from '@mui/icons-material/Close';

interface PreviewProps {
    open: boolean
    setOpen: any
    dxf: string
    busy: boolean
    setBusy: any
};

const Title = (props: {previewTitle: string, onClose: any}) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
            <Typography variant="h4">{props.previewTitle}</Typography>
            <IconButton onClick={props.onClose} >
                <CloseIcon sx={{ color: "black" }} />
            </IconButton>
        </Box>
    )
};

export const Spinner = (props: {busy: boolean, paddingTop?: string}) => {
    const drawingBusy = useLabel("draw.drawing");

    return (
        props.busy ? <Box sx={{height: "100%", alignItems: "center", justifyContent: "center", diplay: "flex", flexDirection: "column", paddingTop: props.paddingTop ?? "30vh"}}>
            <Box sx={{alignItems: "center", justifyContent: "center", display: "flex"}}><Box><CircularProgress /></Box></Box>
            <Box sx={{alignItems: "center", justifyContent: "center", diplay: "flex", flexDirection: "column"}}><Typography>{drawingBusy}</Typography></Box>
        </ Box> : <></>
    )
}

const Preview = (props: PreviewProps) => {
    const previewTitle = useLabel("draw.preview");
    const onClose = () => {
        props.setOpen(false);
    };

    useEffect(() => {
        RenderModalContent(props.dxf, props.busy, props.setBusy);
    });

    if (props.dxf === undefined) {
        return <></>
    } else {
        return <Modal 
            hideBackdrop
            open={props.open} 
            onClose={onClose}
            sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                backgroundColor: "white"
            }}
        >

            <Box sx={{ 
                width: "90%", 
                height: "90%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
                pb: 8
            }}>
                <Title previewTitle={previewTitle} onClose={onClose} />
                <Spinner busy={props.busy}/>
                <Box id="dxf-modal" sx={{display: props.busy ? "none" : "block"}}></Box>

            </Box>
        </Modal>
    }
};

function progressCallback(phase: string, processedSize: number, totalSize: number, setBusy: any, viewer: any): void {
    console.log("Preview: " + phase)
    
    if (phase === "prepare") {
        viewer.FitView();
        setBusy(false);
    }
};

const RenderModalContent = (dxf: string, busy: boolean, setBusy: any) => {
    if (document.getElementById("dxf-modal") !== null && dxf !== "" && busy) {
        const container = document.getElementById("dxf-modal");
        if (container !== null && !container.firstChild) {
            const viewer = new DxfViewer(container);
            viewer.SetSize(800, 800);
            viewer.Load({
                url: dxf, 
                progressCbk: (phase: string, processedSize: number, totalSize: number) => progressCallback(phase, processedSize, totalSize, setBusy, viewer)
            });
        }

    };
};

export default Preview;