import { DataSetChange } from "../../../../components/DataTable/DataTable";
import { Error } from "../../../../types/common";

export function updateChange<T extends EmptyRecord>(changes: Array<DataSetChange<T>>, update: DataSetChange<T>) {
    for (let i=0; i < changes.length; i++) {
        const change = changes[i];
        if (update.old_record?.id !== undefined && change.record?.id === update.old_record?.id) {
            changes[i] = {...change, record: update.record}
            return changes
        }
    };
    return [...changes, update];
};

export function deleteChange<T extends EmptyRecord>(changes: Array<DataSetChange<T>>, deleteChange: DataSetChange<T>) {
    for (let i=0; i < changes.length; i++) {
        const change = changes[i];
        if (deleteChange.old_record?.id !== undefined && change.record?.id === deleteChange.old_record?.id) {
            return [...changes.slice(0, i), ...changes.slice(i + 1)]
        }
    }
    return [...changes, deleteChange];
};

export interface EmptyRecord {
    id: string
    error?: Error | null
};