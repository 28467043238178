import { useState, useContext } from "react";
import { useRecoilState } from "recoil";
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Tooltip, IconButton, Button } from "@mui/material";
import { useLabel } from "../../../../hooks";
import { APIStateContext } from "../../../../state/context";
import { formatTimestamp } from "../../../../utils";
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from "react-router-dom";
import { ProfileHeader } from "../../../../types/profile";
import { profileState } from "../../../../state/profile";
import { ConfirmationDialog } from "../../../../components";

const RefreshButton = () => {
    const [refreshing, setRefreshing] = useState(false);
    const refreshLabel = useLabel("labels.refresh")
    const api = useContext(APIStateContext);

    return <Box sx={{ 
                width: "100%", 
                paddingRight: 8,
                paddingBottom: 2,
                justifyContent: "flex-end",
                alignItems: "flex-end",
                display: "flex"
            }}>
                {refreshing ? <Box><CircularProgress size={24}/></Box> : <></>}
                <Box>
                    <Tooltip title={refreshLabel}>
                        <IconButton onClick={() => {
                            setRefreshing(true);
                            api.refreshProfiles().then(() => {
                                setRefreshing(false);
                            })
                        }} >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
};

const TableHeader = () => {
    const profileNameHeader = useLabel("existingProfiles.name");
    const symbolsFileNameHeader = useLabel("existingProfiles.symbolsFileName")
    const profileCreationTimestampHeader = useLabel("existingProfiles.creationTimestamp");
    const profileUpdateTimestampHeader = useLabel("existingProfiles.updateTimestamp");
    
    return <TableHead>
        <TableRow>
            <TableCell></TableCell>
            <TableCell>{profileNameHeader}</TableCell>
            <TableCell>{symbolsFileNameHeader}</TableCell>
            <TableCell>{profileCreationTimestampHeader}</TableCell>
            <TableCell>{profileUpdateTimestampHeader}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>
};

const DownloadIconButton = (props: {onDownload: any}) => {
    const tooltip = useLabel("labels.download");

    return <Tooltip title={tooltip}>
        <Button onClick={props.onDownload}
            variant="outlined"
            sx={{ padding: 1, minWidth: 0, borderRadius: "24px"}}
            color="primary"
        >
            <DownloadIcon fontSize="small" />
        </Button>
    </Tooltip>
};

const DeleteIconButton = (props: {onDelete: any}) => {
    const tooltip = useLabel("labels.delete");
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    return <>
        <Tooltip title={tooltip}>
            <Button onClick={(event: any) => {event.stopPropagation(); setOpenDeleteConfirmation(true);}} 
                variant="outlined"
                sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                color="error"
            >
                <DeleteIcon fontSize="small" />
            </Button> 
        </Tooltip>
        <ConfirmationDialog
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            onConfirm={props.onDelete}
            textLabelKey="labels.confirmDelete"
        />
    </>
};

const ProfileRow = (props: { profile: ProfileHeader }) => {
    const api = useContext(APIStateContext);
    const navigate = useNavigate();

    const onDownload = async (event: any) => {
        event.stopPropagation();
        await api.downloadFile(props.profile.symbols_file.name, props.profile.symbols_file.download_url)
    };

    const onDelete = async (event: any) => {
        event.stopPropagation();
        await api.deleteProfile(props.profile.id);
    };

    return <TableRow hover onClick={(event: any) => {
        navigate("/dashboard/profiles/" + props.profile.id)
    }} sx={{ cursor: "pointer"}}>
        <TableCell><PersonIcon sx={{ color: "green" }}/></TableCell>
        <TableCell>{props.profile.name}</TableCell>
        <TableCell>{props.profile.symbols_file.name}</TableCell>
        <TableCell>{formatTimestamp(props.profile.creation_timestamp)}</TableCell>
        <TableCell>{formatTimestamp(props.profile.update_timestamp)}</TableCell>
        <TableCell><DownloadIconButton onDownload={onDownload}/><DeleteIconButton onDelete={onDelete} /></TableCell>
    </TableRow>
};

const TableItems = (props: {profiles: Array<ProfileHeader>}) => {
    return <TableBody>{props.profiles.map((profile: ProfileHeader) => <ProfileRow profile={profile} key={profile.id} />)}</TableBody>
};

const ExistingProfiles = (props: any) => {
    const [profiles, _] = useRecoilState(profileState);
    return <Box sx={{ margin: 8 }}>
        <RefreshButton />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: "80%" }} >
                <TableHeader />
                <TableItems profiles={profiles.profileHeaders}/>
            </Table>
        </TableContainer>
    </Box>
};

export default ExistingProfiles;
