import { CreateProjectRequest } from "../../../../api/requests/project";
import { CreateProjectResponse } from "../../../../api/responses/project";
import { SelectedFile } from "../../../../components/S3FileUpload";
import { IAPIStateContext } from "../../../../state/context";
import { DrawSettings, FileStatus, ProjectType } from "../../../../types/project";

export async function createProject(
    projectName: string, 
    projectType: ProjectType, 
    csvFile: SelectedFile,
    tmfFile: SelectedFile,
    gpsFile: SelectedFile,
    drawSettings: DrawSettings,
    profileID: string,
    companyID: string,
    onValidationError: any,
    api: IAPIStateContext
): Promise<CreateProjectResponse | undefined> {
    var [tmf, gps, csv]: Array<SelectedFile | null> = [null, null, null];
    if (projectType === ProjectType.GPS) {
        if (csvFile.name === "" || csvFile.status !== FileStatus.UPLOADED) {
            onValidationError("error.client.invalidFile");
            return;
        } else {
            csv = csvFile;
        }
    } else {
        if (tmfFile.name === "" || tmfFile.status !== FileStatus.UPLOADED) {
            onValidationError("error.client.invalidFile");
            return;
        } else {
            tmf = tmfFile;
        }
        if (gpsFile.name !== "" && gpsFile.status === FileStatus.UPLOADED) {
            gps = gpsFile
        }
    }

    const createProjectRequest: CreateProjectRequest = {
        project_input: {
            name: projectName,
            company_id: companyID,
            project_type: projectType,
            profile_id: profileID,
            draw_settings: drawSettings,
            csv_file: csv,
            tmf_file: tmf,
            gps_file: gps,
        }
    };

    return await api.createProject(createProjectRequest)
}