import React from 'react';
import './App.css';
import ThemeProvider from './theme/provider';
import Router from './routes';
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
