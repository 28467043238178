import { Box, Button, Dialog, MenuItem, Modal, Select } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { TextInput } from "../../components";
import { useLabel } from "../../hooks";
import { APIStateContext } from "../../state/context";
import { Role } from "../../types/auth";

const NameInput = (props: {name: string, setName: Dispatch<SetStateAction<string>>}) => {
    return <TextInput 
        label={useLabel("userLabels.name")} 
        value={props.name} 
        onChange={(event: any) => props.setName(event.target.value)}
    />
};

const EmailInput = (props: {email: string, setEmail: Dispatch<SetStateAction<string>>}) => {
    return <TextInput 
        label={useLabel("labels.email")} 
        value={props.email} 
        onChange={(event: any) => props.setEmail(event.target.value)}
        type="email"
    />
};

const PasswordInput = (props: {password: string, setPassword: Dispatch<SetStateAction<string>>, confirmPassword: string}) => {
    const areEqualAndFilled = props.password === props.confirmPassword || props.confirmPassword === "";
    const passwordIsLongEnough = props.password === "" || props.password.length >= 8;
    const isValid = areEqualAndFilled && passwordIsLongEnough

    const passwordTooShort = useLabel("userLabels.passwordShort");
    const passwordsDontMatch = useLabel("userLabels.passwordsDontMatch")
    
    var helperText = "";
    if (isValid) {
        helperText = "";
    } else if (!passwordIsLongEnough) {
        helperText = passwordTooShort;
    } else if (!areEqualAndFilled) {
        helperText = passwordsDontMatch;
    }

    return <TextInput 
        label={useLabel("labels.password")} 
        value={props.password} 
        onChange={(event: any) => props.setPassword(event.target.value)}
        type="password"
        error={!isValid}
        helperText={helperText}
    />
};

const ConfirmPasswordInput = (props: {confirmPassword: string, setConfirmPassword: Dispatch<SetStateAction<string>>, password: string}) => {
    return <TextInput 
        label={useLabel("labels.confirmPassword")} 
        value={props.confirmPassword} 
        onChange={(event: any) => props.setConfirmPassword(event.target.value)}
        type="password"
        error={props.password !== props.confirmPassword && props.confirmPassword !== ""}
    />
};

const RoleInput = (props: {role: Role, setRole: Dispatch<SetStateAction<Role>>}) => {
    return <Select
        value={props.role}
        id="role"
        label=""
        onChange={(event: any) => props.setRole(event.target.value)}
    >
        {[Role.companyAdmin, Role.companyUser].map((role: Role) => <MenuItem key={role} value={role}>{role}</MenuItem>)}
    </Select>
};

const CreateButton = (props: {name: string, email: string, password: string, confirmPassword: string, role: Role, onCreate: () => any}) => {
    const label = useLabel("projectConfig.create")
    const api = useContext(APIStateContext);

    const create = async () => {
        await api.createUser(props.name, props.email, props.role, props.password)
        props.onCreate();
    };

    const isValid = (
        props.name !== "" && props.email.includes("@") && props.password === props.confirmPassword && props.password.length >= 8
    );

    return <Box mt={4}><Button onClick={create} variant="contained" disabled={!isValid}>{label}</Button></Box>
};

const CreateUserForm = (props: {open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, onCreate: () => void}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState(Role.companyUser);

    return <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <Box p={2} sx={{zIndex: 1000, bgColor: "white"}}>
            <NameInput name={name} setName={setName}/>
            <EmailInput email={email} setEmail={setEmail}/>
            <PasswordInput password={password} setPassword={setPassword} confirmPassword={confirmPassword}/>
            <ConfirmPasswordInput confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} password={password}/>
            <RoleInput role={role} setRole={setRole}/>
            <CreateButton 
                name={name} 
                email={email} 
                password={password} 
                confirmPassword={confirmPassword} 
                role={role} 
                onCreate={() => {props.setOpen(false); props.onCreate()}}
            />
        </Box>
    </Dialog>
};

export default CreateUserForm;