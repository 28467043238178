
import { useContext, useEffect, useState } from "react";
import { DataSet, Header, DataSetChange } from "../../../../../components/DataTable/DataTable";
import { ProfileHeader, ProfileRecord } from "../../../../../types/profile";
import { APIStateContext, IAPIStateContext } from "../../../../../state/context";
import { useLabel, useLanguage } from "../../../../../hooks";
import { Language } from "../../../../../language";
import { TableControls, DataTable } from "../../../../../components/DataTable";
import { PaperContainer } from "../../../../../components";
import { Subtitle } from "./common";
import { Button, IconButton, Stack } from "@mui/material";
import ZoomInMap from '@mui/icons-material/ZoomInMap';
import ZoomOutMap from '@mui/icons-material/ZoomOutMap';
import { ChangeType } from "../../../../../types/common";

const pageSize = 10;

function profileHeaderKeys(language: Language): Array<Header<ProfileRecord>> {
    return [
        {
            name: language.modifyData.code,
            field: "code"
        },
        {
            name: language.modifyData.layer,
            field: "layer",
        },
        {
            name: language.modifyData.color,
            field: "color"
        },
        {
            name: language.modifyData.symbol_name,
            field: "symbol_name"
        },
        {
            name: language.modifyData.line_type,
            field: "line_type"
        },
        {
            name: language.modifyData.orientation,
            field: "orientation"
        },
        {
            name: language.modifyData.scale,
            field: "scale"
        },
        {
            name: language.modifyData.description,
            field: "description"
        }
    ]
};

async function reloadRecords(
    api: IAPIStateContext,
    profileID: string | undefined,
    setProfileRecords: any,
    setChanges: any
) {
    const response = await api.getProfileRecords(profileID);
    console.info(response)
    if (!response?.length) {
        const dummyRecord = {
            id: "record0",
            code: "",
            layer: "",
            line_type: "",
            color: "",
            symbol_name: "",
            orientation: 0,
            scale: 0,
            description: "",
        }
        setProfileRecords(new DataSet<ProfileRecord>([dummyRecord]))
        setChanges([
            {
                change_type: ChangeType.INSERT,
                record: dummyRecord,
            }
        ])
    } else {
        setProfileRecords(new DataSet<ProfileRecord>(response));
    }
};


const EditProfileRecords = (props: {profile: ProfileHeader }) => {
    const [recordsLoaded, setRecordsLoaded] = useState(false);
    const [profileRecords, setProfileRecords] = useState<DataSet<ProfileRecord>>(new DataSet([]));
    const language = useLanguage();
    const profileHeaders: Array<Header<ProfileRecord>> = profileHeaderKeys(language);
    const [hideHeaders, setHideHeaders] = useState<boolean>(true);
    const [changes, setChanges] = useState<Array<DataSetChange<ProfileRecord>>>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const api: IAPIStateContext = useContext(APIStateContext);

    const insertRecord = (insert: any) => {
        setChanges([...changes, insert]);
        setProfileRecords(profileRecords.insert(insert));
        return profileRecords;
    };
    const updateRecord = (update: any) => {
        setChanges([...changes, update]);
        setProfileRecords(profileRecords.update(update));
        return profileRecords;
    };
    const deleteRecord = (deleted: any) => {
        setChanges([...changes, deleted]);
        setProfileRecords(profileRecords.delete(deleted));
        return profileRecords;
    };

    async function saveRecords() {
        const records = await api.updateProfileRecords(changes, props.profile.id);
        setProfileRecords(new DataSet<ProfileRecord>(records ?? []));
        setChanges([]);
    };

    function exportData() {
        const csv = profileRecords.records.map((record) => {
            return [
                record.code,
                record.layer,
                record.line_type,
                record.color,
                record.symbol_name,
                record.orientation === 0 ? "" : record.orientation,
                record.scale === 0 ? "" : record.scale,
                "", // Not sure what this is, but otherwise it does not match the format of files received
                record.description
            ].map((item) => `"${item}"`).join(",")
        }).join("\n");
        const file = new Blob([csv], { type: 'text/plain' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = props.profile.name + ".obj";
        link.click();
    }

    useEffect(() => {
        if (props.profile.id !== null && !recordsLoaded && props.profile !== null) {
            reloadRecords(api, props.profile.id, setProfileRecords, setChanges);
            setRecordsLoaded(true);
        }
    }, [props.profile.id, api, recordsLoaded, props.profile]);
    const exportLabel = useLabel("modifyData.export");
    const headers = hideHeaders ? profileHeaders.filter((item) => ["code", "layer", "symbol_name"].includes(item.field)) : profileHeaders;
    return <PaperContainer>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
            <Subtitle labelKey={"profileConfig.editProfileRecords"} />
            <Stack direction="row" spacing={4}>
                <IconButton onClick={() => setHideHeaders(!hideHeaders)}>
                    {hideHeaders ? <ZoomOutMap /> : <ZoomInMap />}
                </IconButton>

                <Button onClick={exportData} variant="contained" sx={{fontSize: 12}}>{exportLabel}</Button>
            </Stack>
        </Stack>
        <DataTable 
            data={profileRecords}
            headers={headers}
            pageNumber={pageNumber}
            pageSize={pageSize}
            insertRecord={insertRecord}
            updateRecord={updateRecord}
            deleteRecord={deleteRecord}
        />
        <TableControls 
            hasChanged={changes.length !== 0}
            pageNumber={pageNumber} 
            setPageNumber={setPageNumber}
            nbPages={Math.ceil(profileRecords.records.length / pageSize)}
            onSave={saveRecords}
            onDiscard={() => {setChanges([]); reloadRecords(api, props.profile.id, setProfileRecords, setChanges);}}
        />
    </PaperContainer>
};

export default EditProfileRecords;