import React from 'react';
import { IRoute } from './types';
import Dashboard from '../screens/dashboard';
import { Navigate } from 'react-router-dom';
import { panels } from "../screens/dashboard/panels";
import { Panel } from "../screens/dashboard/types";
import { Login } from '../screens/auth';
import Settings from '../screens/settings';
import StateLoader from '../state/loader';
import EditProfile from '../screens/dashboard/panels/existing-profiles/EditProfile';
import ForgotPassword from '../screens/auth/forgot-password';
import ResetPassword from '../screens/auth/reset-password';

const authenticatedRoutes: Array<IRoute> = [
    {
        path: "/",
        component: <Navigate to="/dashboard" />
    },
    {
        path: "/login",
        component: <Login />
    },
    {
        path: "/dashboard",
        component: <Navigate to={panels.slice(-1)[0].href} />
    },
    {
        path: "/settings",
        component: <StateLoader><Dashboard title="labels.settings"><Settings /></Dashboard></StateLoader>
    },
    /* 
    Add the dashboard panels and wrap them in the Dashboard component
    */
    ...panels.map((panel: Panel) => {
        return {
            path: panel.href,
            component: <StateLoader><Dashboard title={panel.label}>{panel.component}</Dashboard></StateLoader>
        }
    }),
    {
        path: "/dashboard/profiles/:profileID",
        component: <StateLoader><Dashboard title="profiles.existing"><EditProfile /></Dashboard></StateLoader>
    },
    {
        path: "/forgot-password",
        component: <ForgotPassword />
    },
    {
        path: "/reset-password/*",
        component: <ResetPassword />
    },
    {
        path: "*",
        component: <Navigate to="/dashboard" />
    }
]

export default authenticatedRoutes;