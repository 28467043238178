import { useState, useRef, MutableRefObject } from "react";
import { useRecoilValue } from "recoil";
import { useLabel } from "../../hooks";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { selectCompany } from "../../state/auth";
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { FileStatus } from "../../types/project";
import { S3FileUploadProps, setFileFunc, SetFileStatusFunc } from "./types";
import { handleFileUpload } from "./functions";

const FileUploadButton = (props: {setFile: setFileFunc, setFileStatus: SetFileStatusFunc}) => {
    const companyID = useRecoilValue(selectCompany)?.id;
    const inputFile: MutableRefObject<any> = useRef(null);
    return <IconButton onClick={() => {
        if (inputFile.current != null) {
            inputFile.current.click();
        }
    }}>
        <CreateNewFolderIcon color="primary"/>
        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} 
            onChange={(event) => {
                handleFileUpload(companyID, props.setFile, event.target.files, props.setFileStatus);
            }}
        />
    </IconButton>

};

const Status = (props: {status: FileStatus}) => {
    if (props.status === FileStatus.UPLOADING) {
        return <CircularProgress size={12} className="circular-progress"/>
    } else if (props.status === FileStatus.UPLOADED) {
        return <CheckCircleOutlineIcon color="success" className="success-icon"/>
    } else if (props.status === FileStatus.FAILED) {
        return <ErrorIcon sx={{ color: "red" }} className="error-icon"/>
    } else {
        return <div className="hello"></div>
    }
}


const S3FileUpload = (props: S3FileUploadProps) => {
    const label = useLabel(props.labelKey);
    const [uploadStatus, setUploadStatus] = useState(FileStatus.NONE);

    return <Box sx={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "60%",
        color: "grey.500",
        border: 5,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "8px",
        p: 1,
        mb: 1
    }}>
        <Box sx={{ display: "flex", alignItems: "center"}} >
            <FileUploadButton setFile={props.setFile} setFileStatus={setUploadStatus} />
            <Typography sx={{ color: "black" }}>{label}: </Typography>
            <Typography sx={{ ml: 1, color: "black" }}>{props.file?.name}</Typography>
        </Box>
        <Status status={uploadStatus} />
    </Box>
};

export default S3FileUpload;