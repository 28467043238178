import { useState } from 'react';
import { Box, Container, Button, Typography } from "@mui/material";
import { LogoLarge, TextInput } from "../../../components";
import { useLabel } from '../../../hooks';
import client from '../../../api';
import { UpdateUserPasswordRequest } from '../../../api/requests/user';
import { UpdateUserPasswordResponse } from '../../../api/responses/user';
import { Link, useSearchParams } from "react-router-dom";

const styles = {
    logoBox: {
        justifyContent: "center",
        display: "flex",
        paddingBottom: 6
    }
}

const Main = (props: any) => {
    const { children } = props;
    return <Box 
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            minHeight: '100vh',
            justifyContent: "center"
        }}>
        {children}
    </Box>
}

const Header = () => {
    return <>
        <Typography
            color="textPrimary"
            variant="h4"
        >
            {useLabel("forgotPassword.reset")}
        </Typography>
    </>
};

const Logo = () => {
    return <Link to="/">
        <Box sx={styles.logoBox}><Box><LogoLarge width={180} height={150}/></Box>
        </Box>
    </Link>
}

const PasswordInput = (props: {password: string, confirmPassword: string, setPassword: any}) => {
    return <TextInput
        id="password-input"
        label={useLabel("labels.password")}
        error={props.password !== props.confirmPassword && props.confirmPassword !== ""}
        onChange={(event: any) => props.setPassword(event.target.value)}
        value={props.password}
        type="password"
    />
};

const ConfirmPasswordInput = (props: {password: string, confirmPassword: string, setConfirmPassword: any}) => {
    return <TextInput
        id="confirm-password-input"
        label={useLabel("labels.confirmPassword")}
        error={props.password !== props.confirmPassword && props.confirmPassword !== ""}
        onChange={(event: any) => props.setConfirmPassword(event.target.value)}
        value={props.confirmPassword}
        type="password"
    />
};

const Feedback = () => {
    return <Typography>{useLabel("forgotPassword.resetComplete")}</Typography>
}

const SubmitButton = (props: {onSubmit: any, waiting: boolean}) => {

    return <Box sx={{ py: 2 }}>
        <Button
            id="login-button"
            color="primary"
            disabled={props.waiting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={props.onSubmit}
        >
        {useLabel("labels.signIn")}
        </Button>
    </Box>
}

async function handleSubmit(
    password: string,
    token: string,
    setWaiting: any,
    setDone: any,

) {
    setWaiting(true);
    const request: UpdateUserPasswordRequest = {
        token: token,
        update: {password: password}
    };
    try {
        await client.updateUserPassword(request).then((response: UpdateUserPasswordResponse) => {
            setWaiting(false);
            setDone(true);
        })
    } catch(error) {
        alert(error);
        console.log(error);
    } finally {
        setWaiting(false);
    }
}

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [done, setDone] = useState(false);
    const [waiting, setWaiting] = useState(false);
    let searchParams = useSearchParams()[0];
    let token = searchParams.get("token") || "";

    console.log(token)
    const onSubmit = () => handleSubmit(
        password,
        token,
        setWaiting,
        setDone
    );

    
    return <Main>
        <Container maxWidth="sm">
            <Logo />
            <Header />
            {done ? <Feedback /> : <>
                <PasswordInput password={password} setPassword={setPassword} confirmPassword={confirmPassword}/>
                <ConfirmPasswordInput password={password} setConfirmPassword={setConfirmPassword} confirmPassword={confirmPassword}/>
                <SubmitButton onSubmit={onSubmit} waiting={waiting}/>
            </>}
        </Container>
    </Main>
};

export default ResetPassword;
