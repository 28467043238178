import { useContext, useState } from "react";
import { Typography, Checkbox, FormControlLabel, Box, FormControl, MenuItem, InputLabel, Select, Button } from "@mui/material";
import { TextInput, PaperContainer, S3FileUpload } from "../../../../components";
import { useLabel } from "../../../../hooks/language";
import { ProfileHeader } from "../../../../types/profile";
import { useProfiles } from "../../../../state/profile";
import { defaultUploadFile } from "../../../../data/defaultValues";
import { SelectedFile } from "../../../../types/project";
import { APIStateContext } from "../../../../state/context";
import { useNavigate } from "react-router-dom";


enum CreateType {
    COPY = "COPY",
    UPLOAD = "UPLOAD",
    EMPTY = "EMPTY"
};

const Subtitle = (props: { labelKey: string }) => {
    const label = useLabel(props.labelKey);

    return <Typography variant="h4" style={{marginBottom: 20, marginTop: 30}}>{label}</Typography>
};

const NameInput = (props: {name: string, setName: any}) => {
    return <>
        <Subtitle labelKey="profileConfig.name" />
        <TextInput
            label={useLabel("profileConfig.name")}
            error={false}
            onChange={(event: any) => props.setName(event.target.value)}
            value={props.name}
            type="string"
        />
    </>
};

const ObjectCodeLengthInput = (props: {objectCodeLength: number, setObjectCodeLength: any}) => {
    return <>
        <Subtitle labelKey="profileConfig.objectCodeLength" />
        <TextInput
            label={useLabel("profileConfig.objectCodeLength")}
            error={false}
            onChange={(event: any) => props.setObjectCodeLength(event.target.value)}
            value={props.objectCodeLength.toString()}
            type="number"
        />
    </>
};


export const CreateTypeCheckbox = (props: {
    selectedType: CreateType,
    checkboxType: CreateType,
    label: string,
    setType: any,
}) => {
    const onChange = (event: any, targetCreateType: CreateType) => {
        if (event.target.checked) {
            props.setType(targetCreateType);
        }
    };
    return <FormControlLabel 
        label={props.label}
        control={<Checkbox
            checked={props.selectedType === props.checkboxType}
            onChange={(event: any) => onChange(event, props.checkboxType)}
        />}
    />
};

export const TypeInput = (props: {type: CreateType, setType: any}) => {
    const copyLabel = useLabel("profileConfig.copy");
    const uploadLabel = useLabel("profileConfig.uploadObj");
    const emptyLabel = useLabel("profileConfig.empty");

    return <>
        <Subtitle labelKey="profileConfig.selectCreateType" />
        <CreateTypeCheckbox 
            selectedType={props.type}
            checkboxType={CreateType.COPY}
            label={copyLabel}
            setType={props.setType}
        />
        <CreateTypeCheckbox 
            selectedType={props.type}
            checkboxType={CreateType.UPLOAD}
            label={uploadLabel}
            setType={props.setType}
        />
         <CreateTypeCheckbox 
            selectedType={props.type}
            checkboxType={CreateType.EMPTY}
            label={emptyLabel}
            setType={props.setType}
        />
    </>
};

const SelectProfileToCopy = (props: { type: CreateType, copiedProfileID: string, setCopiedProfileID: any }) => {
    const profiles = useProfiles();
    const label = useLabel("projectConfig.profile");

    if (profiles.length !== 0 && props.copiedProfileID == null) {
        props.setCopiedProfileID(profiles[0].id);
    };
    return <Box sx={{ display: props.type === CreateType.COPY ? "block" : "none", width: "100%"}}>
        <Subtitle labelKey="projectConfig.profile" />
        <FormControl sx={{ width: "100%"}} >
            <InputLabel htmlFor="profile">{label}</InputLabel>
            <Select
                value={props.copiedProfileID}
                id="profile"
                label={label}
                onChange={(event: any) => props.setCopiedProfileID(event.target.value)}
            >
                {profiles.map((profile: ProfileHeader) => <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>)}
            </Select>
        </FormControl>
    </Box>
};

const UploadFiles = (props: {type: CreateType, objFile: SelectedFile, setObjFile: any, dwgFile: SelectedFile, setDwgFile: any}) => {
    return <Box sx={{ display : props.type !== CreateType.COPY ? "block" : "none" }}>
        <Subtitle labelKey="projectConfig.files" />
        {
            props.type === CreateType.UPLOAD ? <S3FileUpload
                labelKey="profileConfig.uploadObj"
                extensions={[".obj"]}
                file={props.objFile}
                setFile={props.setObjFile}
            />  : null
        }
        <S3FileUpload
            labelKey="profileConfig.uploadSymbols"
            extensions={[".dwg"]}
            file={props.dwgFile}
            setFile={props.setDwgFile}
        />
    </Box>
};

const CreateButton = (props: any) => {
    const label = useLabel("projectConfig.create")
    const create = () => {
        if (props.type === CreateType.COPY) {
            return props.copy();
        } else {
            return props.create();
        }
    };

    const disabled = props.type === CreateType.UPLOAD ? (
        props.dwgFile.hash === "" || props.objFile.hash === ""
    ) : (
        props.type === CreateType.EMPTY ? (
            props.dwgFile.hash === ""
        ) : false
    )

    return <Box mt={4}><Button onClick={create} variant="contained" disabled={disabled}>{label}</Button></Box>
}

const NewProfile = (props: any) => {
    const [name, setName] = useState("");
    const [objectCodeLength, setObjectCodeLength] = useState(2);
    const [type, setType] = useState(CreateType.COPY);
    const [copiedProfileID, setCopiedProfileID] = useState("");
    const [dwgFile, setDwgFile] = useState(defaultUploadFile);
    const [objFile, setObjFile] = useState(defaultUploadFile)
    const uploadProps = {
        dwgFile, setDwgFile, objFile, setObjFile, type
    };
    const api = useContext(APIStateContext);
    const navigate = useNavigate();
    
    return <PaperContainer>
        <NameInput name={name} setName={setName} />
        <TypeInput type={type} setType={setType} />
        {type === CreateType.UPLOAD ? <ObjectCodeLengthInput objectCodeLength={objectCodeLength} setObjectCodeLength={setObjectCodeLength} /> : <></>}
        <SelectProfileToCopy 
            type={type} 
            copiedProfileID={copiedProfileID}
            setCopiedProfileID={setCopiedProfileID}
        />
        <UploadFiles {...uploadProps} />
        <CreateButton 
            type={type} 
            dwgFile={dwgFile}
            objFile={objFile}
            copy={async () => {
                await api.copyProfile(copiedProfileID, name);
                await api.refreshProfiles();
                navigate("/dashboard/existing-profiles");
            }}
            create={async () => {
                await api.createProfile(name, objectCodeLength, objFile, dwgFile);
                await api.refreshProfiles();
                navigate("/dashboard/existing-profiles")
            }}
        />
    
    </PaperContainer>
};

export default NewProfile;
