import { APIException, ParseErrorType } from "../api/errors/errors";
import { ChangeType, Error } from "./common";

export enum ProjectType {
    LOCAL = "LOCAL_PROJECT",
    GPS = "GPS_COORDINATES"
};


export enum FileStatus {
    NONE = "NONE",
    UPLOADED = "UPLOADED",
    FAILED = "FAILED",
    UPLOADING = "UPLOADING"
};

export interface SelectedFile {
    name: string,
    hash: string,
    url: string,
    status: FileStatus
};

export interface DataFiles {
    csv?: string
    tmf?: string
    gps?: string
    obj?: string
};

export interface DrawSettings {
    ppd1: number
    is_3d: boolean
    polylines_3d: boolean
    p_layer: string
    q_layer: string
    point_layer: string | null
    height_block_prefix: string
    height_block_attribute: string
};

export interface ProjectFile {
    name: string
    id: string
    url: string
    upload_timestamp: number
    uploaded_by: string
};

export interface Project {
    id: string
    name: string
    project_type: ProjectType
    company_id: string
    profile_id: string
    draw_settings: DrawSettings
    gps_file: ProjectFile | null
    csv_file: ProjectFile | null
    tmf_file: ProjectFile | null
    creation_timestamp: number
    update_timestamp: number
    created_by: string
    updated_by: string
};

export interface ProjectUpdate {
    id: string
    name: string
    company_id: string
    profile_id: string
    draw_settings: DrawSettings
    csv_file: SelectedFile
    gps_file: SelectedFile
    tmf_file: SelectedFile
}

export interface ProjectInput {
    name: string
    company_id: string
    project_type: ProjectType
    draw_settings: DrawSettings
    gps_file: SelectedFile | null
    csv_file: SelectedFile | null
    tmf_file: SelectedFile | null
    profile_id: string
};

export interface ProjectFileLine {
    id: string
    error?: Error | null
    line: string
}

export interface RawProjectRecord {
    id: string
    error?: Error | null
    point_number?: string
    coordinate_x?: string
    coordinate_y?: string
    coordinate_z?: string
    info?: string
};

export interface Coordinate {
    x: number
    y: number
    z: number
}

export interface ProjectRecord {
    id: string
    error?: Error | null
    identification_code: string
    object_code: string
    line_code: number
    line_number: number
    excentricity: string
    setup_height: number
    coordinate: Coordinate
    point_number: string
    attributes: string
    sequence_number: number
};

interface GenericProjectRecordChange<Record> {
    change_type: ChangeType
    record?: Record | null
    old_record?: Record | null
    after_record?: Record | null
};

export interface ProjectFileLineChange extends GenericProjectRecordChange<ProjectFileLine> {};
export interface RawProjectRecordChange extends GenericProjectRecordChange<ProjectRecord> {};

export interface ProjectFileLinesParseResult {
    error: ParseErrorType
    nb_records: number
    nb_errors: number
    page: Array<ProjectFileLine>
};

export interface LineParseError {
    label: string
    data: any
    error_code?: string  // Not sure if api always returns label and data, so fallback
    error_params?: any
}

export interface ParseRecord {
    line: ProjectFileLine
    raw_record: RawProjectRecord | null
    record: ProjectRecord | null
    error: LineParseError | null
}

export interface ParseResult {
    error?: ParseErrorType
    nb_records: number
    nb_errors: number
    page: Array<ParseRecord>
};

export function isProjectFileLinesParseResult(parseResult: ParseResult): boolean {
    return parseResult.error === ParseErrorType.INVALID_FILE;
};

export function isParseResult(parseResult: ProjectFileLinesParseResult | ParseResult): boolean {
    return (
        ((<ParseResult>parseResult).error === ParseErrorType.PARSING_FAILED) ||
        ((<ParseResult>parseResult).error === ParseErrorType.VALIDATION_FAILED) ||
        ((<ParseResult>parseResult).error === ParseErrorType.CONVERSIONS_FAILED) ||
        ((<ParseResult>parseResult).error === null) ||
        ((<ParseResult>parseResult).error === undefined)
    )
};

export interface ConversionPoint {
    included: boolean
    identifier: string
    local_coordinate: Coordinate
    expected_coordinate: Coordinate
    calculated_coordinate: Coordinate
};

export interface Helmert {
    a: number
    b: number
    c: number
    d: number
    dz: number
};

export interface Conversion {
    origin_point_number: string
    local: boolean
    points: Array<ConversionPoint>
    helmert: Helmert
};

export interface BasePointOutput {
    point_number: string
    local_coordinate: Coordinate
    gps_coordinate: Coordinate
}