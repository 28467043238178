import { useState, ReactElement } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Navigate } from "react-router-dom"; 
import DashboardNavbar from "./dashboard-navbar";
import DashboardSidebar from "./dashboard-sidebar";
import { useIsLoggedIn } from '../../hooks';
import { PanelTitle } from '../../components';

const DashboardBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

interface DashboardProps {
  title: string
  children: ReactElement
}

const Dashboard = (props: DashboardProps) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  
  return useIsLoggedIn() ? (
    <>
      <DashboardBody>
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <PanelTitle title={props.title} />
          {props.children}
        </Box>
      </DashboardBody>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  ) : <Navigate to="/login" />;
};

export default Dashboard;