import { Conversion, ParseResult, Project, ProjectFileLine, ProjectRecord } from "../../types/project";

interface ProjectFileUploadFields {
    key: string
    AWSAccessKeyID: string
    policy: string
    signature: string
};

export interface ProjectFileUploadURLResponse {
    url: string
    fields: ProjectFileUploadFields
};

export interface CreateProjectResponse {
    project: Project
};

export interface GetProjectsResponse {
    projects: Array<Project>
};

export interface DeleteProjectResponse {};

export interface UpdateProjectResponse {
    project: Project
};

export interface LoadActiveProjectResponse {
    project: Project
    parse_result: ParseResult
    conversions: Array<Conversion>
};

export type GetRawRecordsResponse = {
    records: Array<ProjectFileLine>
};

export type GetRecordsResponse = {
    records: Array<ProjectRecord>
};

export interface RecalculateConversionsResponse {
    conversions: Array<Conversion>
};

export interface DownloadableFile {
    name: string
    url: string
}

export enum DrawingStatus{
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    BUSY = "BUSY"
}

export interface GetDrawingStatusResponse {
    status: DrawingStatus
    dxf: DownloadableFile | null
    dwg: DownloadableFile | null
    error: string | null
};

export interface InitiateDrawingResponse {
    company_id: string
    project_id: string
};