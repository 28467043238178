import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from '../../../state/auth';
import { Box, Container, Button, Typography } from "@mui/material";
import { TextInput, LogoLarge } from "../../../components";
import { useLabel, useLanguage } from '../../../hooks';
import LoginRequest from '../../../api/requests/login';
import client from '../../../api';
import LoginResponse from '../../../api/responses/login';
import { APIException } from '../../../api/errors/errors';
import { Language } from '../../../language';

const styles = {
    logoBox: {
        justifyContent: "center",
        display: "flex",
        paddingBottom: 6
    }
}

const Main = (props: any) => {
    const { children } = props;
    return <Box 
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            minHeight: '100vh',
            justifyContent: "center"
        }}>
        {children}
    </Box>
}

const Header = () => {
    return <>
        <Typography
            color="textPrimary"
            variant="h4"
        >
            {useLabel("labels.signIn")}
        </Typography>
    </>
}

const Logo = () => {
    return <Box sx={styles.logoBox}><Box><LogoLarge width={180} height={150}/></Box></Box>
}

const EmailInput = (props: {email: string, setEmail: any}) => {
    return <TextInput
        id="email-input"
        label={useLabel("labels.email")}
        error={false}
        onChange={(event: any) => props.setEmail(event.target.value)}
        value={props.email}
        type="email"
    />
}

const PasswordInput = (props: {password: string, setPassword: any}) => {
    return <TextInput
        id="password-input"
        label={useLabel("labels.password")}
        error={false}
        onChange={(event: any) => props.setPassword(event.target.value)}
        value={props.password}
        type="password"
    />
}

const SubmitButton = (props: {onSubmit: any, waiting: boolean}) => {

    return <Box sx={{ py: 2 }}>
        <Button
            id="login-button"
            color="primary"
            disabled={props.waiting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={props.onSubmit}
        >
        {useLabel("labels.signIn")}
        </Button>
    </Box>
}

async function handleLogin(
    email: string, 
    password: string, 
    setWaiting: any,
    setAuth: any,
    navigate: any,
    setFeedback: any,
    language: Language
) {
    setWaiting(true);
    const request: LoginRequest = {
        email: email,
        password: password
    }
    try {
        await client.login(request).then((response: LoginResponse) => {
            setAuth(response);
            localStorage.setItem("auth", JSON.stringify(response))
            navigate("/dashboard");
        })
    } catch(error) {
        if (error instanceof APIException) {
            setFeedback(error.format(language));
        }
    } finally {
        setWaiting(false);
    }
};

const ForgotPasswordButton = () =>  {
    return <Link to={"/forgot-password"} style={{ textDecoration: 'none' }}>{useLabel("forgotPassword.label")}</Link>
};

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [waiting, setWaiting] = useState(false);
    const setAuth = useRecoilState(authState)[1];
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState("");
    const language = useLanguage();

    const onSubmit = () => handleLogin(
        email, 
        password, 
        setWaiting,
        setAuth,
        navigate,
        setFeedback,
        language
    );

    
    return <Main>
        <Container maxWidth="sm">
            <Logo />
            <Header />
            <EmailInput email={email} setEmail={setEmail} />
            <PasswordInput password={password} setPassword={setPassword} />
            <ForgotPasswordButton />
            <Typography style={{color: 'red'}}>{feedback}</Typography>
            <SubmitButton onSubmit={onSubmit} waiting={waiting}/>
        </Container>
    </Main>
};

export default Login;
