import { atom, RecoilState, selector, useRecoilValue } from "recoil";
import { AuthUser, Company, Role } from "../types/auth";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

interface LoginState {
    token?: string
    user?: AuthUser
    company: Company
    expiration?: number // unix timestamp
}

const defaultAuthState: LoginState = {
    token: undefined,
    user: undefined,
    company: {
        id: "",
        name: ""
    },
    expiration: undefined
}

const authState: RecoilState<LoginState> = atom({
    key: "authState",
    default: defaultAuthState,
    effects_UNSTABLE: [persistAtom]
});

export function isLoggedIn(auth: LoginState) {
    if ((auth.token == null) || (auth.expiration == null)) {
        return false;
    } else if (auth.expiration < Date.now()) {
        return false;
    }
    return true;
};


const selectIsLoggedIn = selector({
    key: "selectIsLoggedIn",
    get: ({ get }) => {
        const auth = get(authState);
        return isLoggedIn(auth);
    }
});

const selectToken = selector({
    key: "selectToken",
    get: ({ get }) => {
        return get(authState).token
    }
});

const selectUser = selector({
    key: "selectUser",
    get: ({ get }) => {
        return get(authState).user
    }
});

const selectCompany = selector({
    key: "selectCompany",
    get: ({ get }) => {
        return get(authState).company
    }
});

const selectIsCompanyAdmin = selector({
    key: "selectIsCompanyAdmin",
    get: ({ get }) => {
        const role = get(authState).user?.role
        return role != null && [Role.appAdmin, Role.companyAdmin].includes(role) 
    }
});

const selectIsAppAdmin = selector({
    key: "selectIsAppAdmin",
    get: ({ get }) => {
        const role = get(authState).user?.role
        return role != null && role === Role.appAdmin;
    }
})

export { 
    Role, 
    authState, 
    selectIsLoggedIn, 
    selectToken, 
    selectUser, 
    selectCompany,
    selectIsCompanyAdmin,
    selectIsAppAdmin
};
export type { LoginState, AuthUser as User, Company };