import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useLabel } from "../../../../hooks";
import { useAppInfo } from "../../../../state/info";
import { useContext, useState } from "react";
import { APIStateContext } from "../../../../state/context";
import { Check } from "@mui/icons-material";

const Info = () => {
    const versionLabel = useLabel("labels.version");
    const contactLabel = useLabel("labels.contact");
    const contactPlaceholderLabel = useLabel("labels.contactMessagePlaceholder");
    const sendLabel = useLabel("labels.send")
    const authorLabel = useLabel("labels.author");
    
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const info = useAppInfo();
    const api = useContext(APIStateContext);

    
    return <Box 
        sx={{
            display: 'flex',
            minHeight: '80vh',
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }}>
            <Box sx={{
                display: 'flex',
                minHeight: '80vh',
                maxWidth: 600,
                justifyContent: "center",
                flexDirection: "column"
            }}
            >

                <Stack direction={"column"} spacing={2} py={2}>
                    <Typography sx={{fontSize: 36}}>{contactLabel}</Typography>
                    <TextField
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        minRows={12}
                        placeholder={contactPlaceholderLabel}
                    />
                    <Button
                        variant="contained"
                        disabled={sent || loading}
                        sx={{
                            fontSize: 18,
                            "&:disabled": {
                                backgroundColor: sent ? "green" : undefined,
                                color: sent ? "white" : undefined,

                            }
                        }}
                        onClick={() => {
                            setLoading(true);
                            api.contact(message).then(() => {
                                setSent(true);
                            }).finally(() => setLoading(false))
                        }}
                    >
                        {sent ? <Check fontSize={"large"}/> : (loading ? <CircularProgress /> : sendLabel)}
                    </Button>

                    <Typography variant="h6">{authorLabel} {info.author}</Typography>
                    <Typography>{versionLabel}: {info.version}</Typography>
                </Stack>
            </Box>
    </Box>
}

export default Info;
