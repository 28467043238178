const stringAvatar = (name: string) => {
  var split_name = name.split(' ');
  if (split_name.length == 1) {
    split_name = split_name.concat(" ")
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${split_name[0][0]}${split_name[1][0]}`,
  };
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export { stringAvatar, stringToColor };