import { useState } from 'react';
import { 
    Avatar, 
    Divider, 
    ListItem, 
    Menu, 
    MenuItem, 
    Typography 
  } from '@mui/material';
import { useCompany, useUser } from '../../../hooks/user';
import { useLabel } from '../../../hooks';
import { stringAvatar } from '../../../utils';
import { AuthUser, Company } from "../../../types/auth";
import { defaultCompany, defaultUser } from "../../../data/defaultValues";
import { useNavigate } from 'react-router-dom';

const UserAvatar = () => {
    const company = useCompany() || defaultCompany;
    const user = useUser() || defaultUser;
  
    const [menuAnchor, setMenuAnchor] = useState(null);
  
    const handleHover = (event: any) => {
      if (menuAnchor !== event.currentTarget) {
        setMenuAnchor(event.currentTarget)
      }
    }
    
    return <>
      <UserMenu 
        user={user} 
        company={company} 
        menuAnchor={menuAnchor}
        setMenuAnchor={setMenuAnchor}
        handleClose={() => setMenuAnchor(null)}
      />
      <Avatar 
        {...stringAvatar(user.name)} 
        onMouseOver={handleHover} 
      />
    </>
}
  
interface UserMenuProps {
    user: AuthUser
    company: Company
    menuAnchor: any
    setMenuAnchor: any
    handleClose: any
}
  
const UserMenu = (props: UserMenuProps) => {
    const logoutLabel = useLabel("labels.logout");
    const navigate = useNavigate();

    return <Menu
        id="simple-menu"
        anchorEl={props.menuAnchor}
        open={Boolean(props.menuAnchor)}
        onClose={props.handleClose}
        MenuListProps={{ onMouseLeave: props.handleClose }}
    >
        <ListItem dense={true}><Typography variant="h6">{props.user.name}</Typography></ListItem>
        <ListItem><Typography>{props.company.name}</Typography></ListItem>
        <Divider />
        <MenuItem onClick={() => logout(navigate)}>{logoutLabel}</MenuItem>
    </Menu>
};

function logout(navigate: any) {
    window.localStorage.clear();
    navigate("/login")
}

export default UserAvatar;