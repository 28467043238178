import { useRecoilValue } from "recoil";
import { selectCompany, selectIsAppAdmin, selectIsCompanyAdmin, selectUser } from "../state/auth";

const useIsCompanyAdmin = () => {
    return useRecoilValue(selectIsCompanyAdmin);
};

const useCompany = () => {
    return useRecoilValue(selectCompany);
};

const useUser = () => {
    return useRecoilValue(selectUser);
};

const useIsAppAdmin = () => {
    return useRecoilValue(selectIsAppAdmin);
};

export {
    useIsCompanyAdmin,
    useCompany,
    useUser,
    useIsAppAdmin
};