import { atom, RecoilState, selector, useRecoilValue } from "recoil";
import { ProfileHeader } from "../types/profile";

interface ProfileState {
    profileHeaders: Array<ProfileHeader>
};

const defaultProfileState: ProfileState = {
    profileHeaders: []
};

const profileState: RecoilState<ProfileState> = atom({
    key: "profileState",
    default: defaultProfileState
});

const selectProfiles = selector({
    key: "selectProfiles",
    get: ({ get }) => {
        const profile = get(profileState);
        return profile.profileHeaders;
    }
});

const useProfiles = () => useRecoilValue(selectProfiles);

export { profileState, useProfiles };