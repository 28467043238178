import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { PaperContainer } from "../../../../components";
import { TableControls } from "../../../../components/DataTable";
import DataTable, { DataSet, DataSetChange, Header } from "../../../../components/DataTable/DataTable";
import { useLabel, useLanguage } from "../../../../hooks";
import { useAPI } from "../../../../state/context";
import { ActiveProjectState, activeProjectState, useIsActiveProjectSelected } from "../../../../state/project";
import { isParseResult, isProjectFileLinesParseResult, ParseRecord, ParseResult, ProjectFileLine, ProjectRecord, ProjectType, RawProjectRecord } from "../../../../types/project";
import { deleteChange, updateChange } from "./changes";
import { getFileLines, getRawRecords } from "./functions";
import { ParseErrorType } from "../../../../api/errors/errors";

interface FlatProjectRecord extends ProjectRecord {
    coordinateX: number
    coordinateY: number
    coordinateZ: number
}

const pageSize = 20;
const styles = {
    error: {
        color: "red",
        pt: 4,
        px: 8
    }
}
const ModifyData = (props: any) => {
    const activeProjectSelected = useIsActiveProjectSelected();
    const [activeProject, setActiveProject] = useRecoilState(activeProjectState);
    const parseResult = activeProject.parse_result;

    const conversionErrorLabel = useLanguage().error.server.conversion_failed();
    const conversionFailedError = parseResult?.error === ParseErrorType.CONVERSIONS_FAILED ? <Typography sx={styles.error}>
        {conversionErrorLabel}
    </Typography> : <></>
    
    if (!activeProjectSelected) {
        return <Placeholder />
    } else if (activeProject.project !== null && activeProjectSelected !== null && parseResult !== null) {
        if (isProjectFileLinesParseResult(parseResult)) {
            return <>
                {conversionFailedError}
                <ProjectFileLinesTable parseResult={parseResult} projectId={activeProject.project.id}/>
            </>
        } else if (isParseResult(parseResult)) {
            return <>
                {conversionFailedError}
                <RawProjectRecordsTable parseResult={parseResult} activeProject={activeProject}/>
            </>
        };
    };
    return <Placeholder />
};

const Subtitle = (props: { labelKey: string }) => {
    const label = useLabel(props.labelKey);
    return <Typography variant="h4" style={{marginBottom: 20, marginTop: 30}}>{label}</Typography>
};

const Placeholder = () => {
    const label = useLabel("labels.noActiveProjectSelected")
    return <Paper elevation={3} sx={{ padding: 4, margin: 8 }}>
        <Typography>{label}</Typography>
    </Paper>
};

const ProjectFileLinesTable = (props: { parseResult: ParseResult, projectId: string }) => {
    const api = useAPI();
    const language = useLanguage();
    const labels: any = language.modifyData.rawLabels
    const [activeProject, setActiveProject] = useRecoilState(activeProjectState);

    var headers: Array<Header<ProjectFileLine>> = ["line"].map((key: string) => 
    {
        return {
            name: labels[key],
            field: key as keyof ProjectFileLine
        }
    }
)
    const page = getFileLines(props.parseResult.page)
    const [fileLines, setFileLines] = useState<DataSet<ProjectFileLine>>(new DataSet(page ?? []));
    const [changes, setChanges] = useState<Array<DataSetChange<ProjectFileLine>>>([]);
    const [pageNumber, setPageNumber] = useState(0);

    const insertRecord = (insert: any) => {
        setChanges([...changes, insert]);
        setFileLines(fileLines.insert(insert));
        return fileLines;
    };
    const updateRecord = (update: any) => {
        setChanges(updateChange(changes, update));
        setFileLines(fileLines.update(update));
        return fileLines;
    };
    const deleteRecord = (deleted: any) => {
        setChanges(deleteChange(changes, deleted));
        setFileLines(fileLines.delete(deleted));
        return fileLines;
    };

    async function saveRecords() {
        const response = await api.updateProjectFileLines(props.projectId, changes);
        if (response) {
            setActiveProject(response);
            const records = getFileLines(response.parse_result.page);
            setFileLines(new DataSet<ProjectFileLine>(records ?? []));
            setChanges([]);
        }
    };

    async function loadPage(pageNumber: number) {
        const response = await api.getProjectRawRecords(props.projectId, pageNumber, pageSize);
        const records = getFileLines(response.records);
        setFileLines(new DataSet<ProjectFileLine>(records ?? []));
        setPageNumber(pageNumber);
    };

    return <PaperContainer>
    <Subtitle labelKey={"activeProject.modifyData"} />
    <DataTable 
        data={fileLines}
        headers={headers}
        pageNumber={0}
        pageSize={pageSize}
        insertRecord={insertRecord}
        updateRecord={updateRecord}
        deleteRecord={deleteRecord}
    />
    <TableControls 
        hasChanged={changes.length !== 0}
        pageNumber={pageNumber} 
        setPageNumber={loadPage}
        nbPages={Math.ceil(props.parseResult.nb_records / pageSize)}
        onSave={saveRecords}
        onDiscard={() => {setChanges([])}}
    />
</PaperContainer>

};

const RawProjectRecordsTable = (props: { parseResult: ParseResult, activeProject: ActiveProjectState  }) => {
    const projectId = props.activeProject.project?.id;
    const api = useAPI();
    const language = useLanguage();
    const exportLabel = useLabel("modifyData.export");
    const labels: any = language.modifyData.rawLabels;

    var headers: Array<Header<RawProjectRecord>> = ["point_number", "coordinate_x", "coordinate_y", "coordinate_z", "distance", "horizontal_angle", "vertical_angle", "info"].map((key: string) => 
        {
            return {
                name: labels[key],
                field: key as keyof RawProjectRecord
            }
        }
    )

    const [fileLines, setFileLines] = useState<DataSet<RawProjectRecord>>(new DataSet(getRawRecords(props.parseResult.page) ?? []));
    const [changes, setChanges] = useState<Array<DataSetChange<RawProjectRecord>>>([]);
    const [pageNumber, setPageNumber] = useState(0);

    const insertRecord = (insert: any) => {
        setChanges([...changes, insert]);
        setFileLines(fileLines.insert(insert));
        return fileLines;
    };
    const updateRecord = (update: any) => {
        setChanges(updateChange(changes, update));
        setFileLines(fileLines.update(update));
        return fileLines;
    };
    const deleteRecord = (deleted: any) => {
        setChanges(deleteChange(changes, deleted));
        setFileLines(fileLines.delete(deleted));
        return fileLines;
    };

    async function saveRecords() {
        const response = await api.updateRawProjectRecords(projectId, changes);
        if (response) {
            const records = getRawRecords(response.parse_result.page);
            setFileLines(new DataSet<RawProjectRecord>(records ?? []));
            setChanges([]);
        }
    };

    async function loadPage(pageNumber: number) {
        const response = await api.getProjectRecords(projectId, pageNumber, pageSize);
        const records = getRawRecords(response.records);
        setFileLines(new DataSet<RawProjectRecord>(records ?? []));
        setPageNumber(pageNumber);
    };

    async function exportRecords(): Promise<void> {
        let records: ParseRecord[] = [];
        let pageIndex = 0;
        let pageSize = 500;
        while (true) {
            const page = await api.getProjectRecords(projectId, pageIndex, pageSize);
            records = records.concat((page.records ?? []) as ParseRecord[]);
            const totalNumberOfPages = Math.ceil(props.parseResult.nb_records / pageSize)
            if (totalNumberOfPages === pageIndex) {
                break;
            } else {
                pageIndex += 1
            }
        }
        let result = "";
        if (props.activeProject.project?.project_type === ProjectType.GPS) {
            result = records.map((parseRecord) => {
                const record = parseRecord.raw_record;
                if (record !== null) {
                    return `${record.point_number},${record.coordinate_x},${record.coordinate_y},${record.coordinate_z},${record.info}`
                } else {
                    return parseRecord.line
                }
            }).join("\n")
        } else {
            // let lines: string[] = [];
            // records.forEach((record: ProjectRecord, index: number) => {
            //     if (record.identification_code === "2") {
            //         // Setup point
            //         lines.push(`13NMs ${record.object_code ? record.object_code : 98} ${record.point_number} ${record.setup_height}`)
            //     } else if (record.identification_code === "3") {

            //     }
            // });
            // result = "\n".join(lines);
        }
        const file = new Blob([result], { type: 'text/plain' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = (props.activeProject.project?.csv_file?.name ?? "export.csv");
        link.click();
    }

    return <PaperContainer>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
            <Subtitle labelKey={"activeProject.modifyData"} />
            <Stack direction="row" spacing={4}>
                {
                    props.activeProject.project?.project_type === ProjectType.GPS ? 
                    <Button onClick={exportRecords} variant="contained" sx={{fontSize: 12}}>{exportLabel}</Button> :
                    <></>
                }
                
            </Stack>
        </Stack>
        <DataTable 
            data={fileLines}
            headers={headers}
            pageNumber={0}
            pageSize={pageSize}
            insertRecord={insertRecord}
            updateRecord={updateRecord}
            deleteRecord={deleteRecord}
        />
        <TableControls 
            hasChanged={changes.length !== 0}
            pageNumber={pageNumber} 
            setPageNumber={loadPage}
            nbPages={Math.ceil(props.parseResult.nb_records / pageSize)}
            onSave={saveRecords}
            onDiscard={() => {setChanges([])}}
        />
    </PaperContainer>

};


export default ModifyData;
