import { useState, useContext, useEffect, Dispatch, SetStateAction } from "react";
import { useRecoilState } from "recoil";
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Tooltip, IconButton, Button, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { APIStateContext } from "../../state/context";
import { useLabel } from "../../hooks";
import { ConfirmationDialog, TextInput } from "../../components";
import { User } from "../../types/user";
import { userListState } from "../../state/users";
import { Role } from "../../types/auth";
import { useIsAppAdmin, useIsCompanyAdmin } from "../../hooks/user";
import CreateUserForm from "./CreateUserForm";

const RefreshButton = (props: {setShouldRefresh: Dispatch<SetStateAction<boolean>>}) => {
    const refreshLabel = useLabel("labels.refresh")

    return <Box sx={{ 
                paddingRight: 8,
                paddingBottom: 2,
                justifyContent: "flex-end",
                alignItems: "flex-end",
                display: "flex"
            }}>
                <Box>
                    <Tooltip title={refreshLabel}>
                        <IconButton onClick={() => {props.setShouldRefresh(true)}} >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
};

const TableHeader = () => {
    const userNameHeader = useLabel("userLabels.name");
    const userEmailHeader = useLabel("userLabels.email")
    const userRoleHeader = useLabel("userLabels.role")
    
    return <TableHead>
        <TableRow>
            <TableCell></TableCell>
            <TableCell>{userNameHeader}</TableCell>
            <TableCell>{userEmailHeader}</TableCell>
            <TableCell>{userRoleHeader}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>
};

const UpdateIconButton = (props: {onUpdate: any}) => {
    const tooltip = useLabel("labels.save");
    return <>
        <Tooltip title={tooltip}>
            <Button onClick={async (event: any) => {event.stopPropagation(); await props.onUpdate(event);}} 
                variant="outlined"
                sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                color="primary"
            >
                <SaveIcon fontSize="small" />
            </Button> 
        </Tooltip>
    </>
};

const DeleteIconButton = (props: {onDelete: any}) => {
    const tooltip = useLabel("labels.delete");
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    return <>
        <Tooltip title={tooltip}>
            <Button onClick={(event: any) => {event.stopPropagation(); setOpenDeleteConfirmation(true);}} 
                variant="outlined"
                sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                color="error"
            >
                <DeleteIcon fontSize="small" />
            </Button> 
        </Tooltip>
        <ConfirmationDialog
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            onConfirm={props.onDelete}
            textLabelKey="labels.confirmDelete"
        />
    </>
};

const UserRow = (props: { user: User, setShouldRefresh: Dispatch<SetStateAction<boolean>> }) => {
    const api = useContext(APIStateContext);

    const [name, setName] = useState(props.user.name);
    const [email, setEmail] = useState(props.user.email);
    const [role, setRole] = useState(props.user.role);

    const onDelete = async (event: any) => {
        event.stopPropagation();
        await api.deleteUser(props.user.id);
        props.setShouldRefresh(true)
    };

    const onUpdate = async (event: any) => {
        event.stopPropagation();
        const response = await api.updateUser(props.user.id, name, email, role);
        setName(response.user.name);
        setEmail(response.user.email);
        setRole(response.user.role);
        props.setShouldRefresh(true)
    };

    return <TableRow>
        <TableCell><PersonIcon sx={{ color: "blue" }}/></TableCell>
        <TableCell><NameField name={name} setName={setName} /></TableCell>
        <TableCell><EmailField email={email} setEmail={setEmail} /></TableCell>
        <TableCell><RoleField role={role} setRole={setRole} /></TableCell>
        <TableCell><UserActionIcons onUpdate={onUpdate} onDelete={onDelete} /></TableCell>
    </TableRow>
};

const NameField = (props: {name: string, setName: Dispatch<SetStateAction<string>>}) => {
    const isCompanyAdmin = useIsCompanyAdmin();
    const label = useLabel("");
    return isCompanyAdmin ? <>
        <TextInput label={label} onChange={(event: any) => {props.setName(event.target.value)}} value={props.name}/>
    </> : <>
        {props.name}
    </>
};

const EmailField = (props: {email: string, setEmail: Dispatch<SetStateAction<string>>}) => {
    const isCompanyAdmin = useIsCompanyAdmin();
    const label = useLabel("");
    return isCompanyAdmin ? <>
        <TextInput label={label} onChange={(event: any) => {props.setEmail(event.target.value)}} value={props.email}/>
    </> : <>
        {props.email}
    </>
};

const RoleField = (props: {role: Role, setRole: Dispatch<SetStateAction<Role>> }) => {
    const isCompanyAdmin = useIsCompanyAdmin();
    return isCompanyAdmin && props.role !== Role.appAdmin ? <>
        <FormControl>
            <Select
                value={props.role}
                id="role"
                label=""
                onChange={(event: any) => props.setRole(event.target.value)}
            >
                {[Role.companyAdmin, Role.companyUser, Role.onHold].map((role: Role) => <MenuItem key={role} value={role}>{role}</MenuItem>)}
            </Select>
        </FormControl>
    </> : <>{props.role}</>
};

const UserActionIcons = (props: {onUpdate: any, onDelete: any}) => {
    return useIsCompanyAdmin() ? <>
        <UpdateIconButton onUpdate={props.onUpdate} />
        <DeleteIconButton onDelete={props.onDelete} />
    </> : <></>

}

const TableItems = (props: {users: Array<User>, setShouldRefresh: Dispatch<SetStateAction<boolean>>}) => {
    const isAppAdmin = useIsAppAdmin();
    const users = isAppAdmin ? props.users : props.users.filter((user: User) => user.role !== Role.appAdmin);
    return <TableBody>{users.map((user: User) => (
        <UserRow user={user} key={user.id} setShouldRefresh={props.setShouldRefresh}/>
    ))}</TableBody>
};

const UserList = (props: any) => {
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const api = useContext(APIStateContext);
    const [users, setUsers] = useRecoilState(userListState);
    const [createOpen, setCreateOpen] = useState(false);
    const label = useLabel("userLabels.create");
    useEffect(
        () => {
            api.listUsers().then(setUsers);
            setShouldRefresh(false);
        }, [shouldRefresh]
    )

    return <Box m={4}>
        <Typography variant="h4">{useLabel("userLabels.manage")}</Typography>

        <Box sx={{ margin: 8 }}>
            <Box sx={{flexDirection: "row", display: "flex"}}>
                <Box sx={{ width: "50%"}}><Button onClick={() => setCreateOpen(true)} variant="contained">{label}</Button></Box>
                <Box sx={{ width: "50%"}}><RefreshButton setShouldRefresh={setShouldRefresh}/></Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: "80%" }} >
                    <TableHeader />
                    <TableItems users={users.users} setShouldRefresh={setShouldRefresh}/>
                </Table>
            </TableContainer>
        </Box>
        <CreateUserForm open={createOpen} setOpen={setCreateOpen} onCreate={() => setShouldRefresh(true)}/>
    </Box>
};

export default UserList;
