import React, { ChangeEvent } from "react";
import { TextField } from "@mui/material";

interface onChangeFunc {
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
}
interface TextInputProps {
    id?: string
    label: string
    helperText?: string
    error?: boolean
    onChange: onChangeFunc
    value: string
    type?: string
    disabled?: boolean
    onClick?: any
    onFocus?: onChangeFunc
}

const TextInput = (props: TextInputProps) => {
    return <TextField
        id={props.id}
        error={props.error || false}
        fullWidth
        helperText={props.helperText || ""}
        label={props.label}
        margin="normal"
        onBlur={() => {}}
        onChange={props.onChange}
        type={props.type || ""}
        value={props.value}
        variant="outlined"
        disabled={props.disabled || false}
        onClick={props.onClick}
        onFocus={props.onFocus}
    />
}

export default TextInput;
