import { atom, RecoilState, selector, useRecoilValue, useRecoilState } from "recoil";
import { User } from "../types/user";

export interface UserListState {
    users: Array<User>
};

const defaultUserListState: UserListState = {
    users: []
};

export const userListState: RecoilState<UserListState> = atom({
    key: "userListState",
    default: defaultUserListState
});

export const selectUserList = selector({
    key: "selectUserList",
    get: ({ get }) => {
        return get(userListState).users;
    }
});

export const useUserList = () => useRecoilValue(selectUserList);
