import { Role, AuthUser, Company } from "../types/auth";
import AppInfoResponse from "../api/responses/appInfo";
import { DrawSettings, FileStatus, SelectedFile } from "../types/project";

export const defaultUser: AuthUser = {
    name: "Unauthorized User",
    id: "0123456789",
    role: Role.companyUser
};

export const defaultCompany: Company = {
    name: "Unknown",
    id: "0213456789"
};

export const defaultInfo: AppInfoResponse = {
    author: "Aron Geerts",
    version: "0.0.0",
    contact: "arongeerts8@gmail.com"
};

export const defaultUploadFile: SelectedFile = {
    name: "",
    hash: "",
    status: FileStatus.NONE,
    url: ""
};

export const defaultDrawSettings: DrawSettings = {
    ppd1: 5,
    is_3d: true,
    polylines_3d: true,
    q_layer: "",
    p_layer: "",
    point_layer: "OCT_KDT",
    height_block_prefix: "hoogtepeil_",
    height_block_attribute: ""
};
