import { 
    mockAppInfoResponse, 
    mockLoginResponse,
    mockProjectFileUploadResponse,
    mockGetProfilesResponse,
    mockCreateProjectResponse,
    mockGetProjectsResponse,
    mockGetProfileRecordsResponse,
    mockUpdateProfileRecordsResponse,
    mockLoadProjectResponse,
    mockRawRecords,
    mockProjectRecords,
    mockDrawResponse,
    mockGetDrawingStatus,
    mockUser
} from "../data/mockData";
import LoginRequest from "./requests/login";
import LoginResponse from "./responses/login";
import AppInfoResponse from "./responses/appInfo";
import APIClient from "./types";
import { CreateProjectRequest, DeleteProjectRequest, DrawRequest, GetDrawingStatusRequest, GetProjectsRequest, GetRecordsRequest, LoadActiveProjectRequest, ProjectFileUploadURLRequest, RecalculateConversionsRequest, UpdateProjectFileLinesRequest, UpdateProjectRecordsRequest, UpdateProjectRequest } from "./requests/project";
import { CreateProjectResponse, DeleteProjectResponse, GetDrawingStatusResponse, GetProjectsResponse, GetRawRecordsResponse, GetRecordsResponse, InitiateDrawingResponse, LoadActiveProjectResponse, ProjectFileUploadURLResponse, RecalculateConversionsResponse, UpdateProjectResponse } from "./responses/project";
import { FileDownloadResponse, FileUploadResponse } from "./responses/files";
import { CopyProfileRequest, CreateProfileRequest, DeleteProfileRequest, GetProfileRecordsRequest, GetProfilesRequest, UpdateProfileHeaderRequest, UpdateProfileRecordsRequest } from "./requests/profile";
import { CopyProfileResponse, CreateProfileResponse, DeleteProfileResponse, GetProfileRecordsResponse, GetProfilesResponse, UpdateProfileHeaderResponse, UpdateProfileRecordsResponse } from "./responses/profile";
import { DownloadFileRequest } from "./requests/common";
import { CreateUserRequest, DeleteUserRequest, ForgotPasswordRequest, GetUserRequest, ListUsersRequest, UpdateUserPasswordRequest, UpdateUserRequest } from "./requests/user";
import { CreateUserResponse, DeleteUserResponse, ForgotPasswordResponse, GetUserResponse, ListUsersResponse, UpdateUserPasswordResponse, UpdateUserResponse } from "./responses/user";
import { Role } from "../types/auth";
import { ContactRequest } from "./requests/contact";

function mockCall<ReturnType>(request: any): Promise<ReturnType> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(request)
        }, 1000); // 1 second
    })
};

function setLoginInfo(token: string, companyID: string, userID: string): void{
    return
}

async function login(request: LoginRequest): Promise<LoginResponse> {
    return mockCall<LoginResponse>(mockLoginResponse);
};

async function getAppInfo(): Promise<AppInfoResponse> {
    return mockCall<AppInfoResponse>(mockAppInfoResponse);
};

async function getProjectFileUploadURL(request: ProjectFileUploadURLRequest): Promise<ProjectFileUploadURLResponse> {
    return mockCall<ProjectFileUploadURLResponse>(mockProjectFileUploadResponse);
};

async function uploadS3File(file: File, signedURL: ProjectFileUploadURLResponse, content: string): Promise<FileUploadResponse> {
    return mockCall<FileUploadResponse>({});
};

async function getProfiles(request: GetProfilesRequest): Promise<GetProfilesResponse> {
    return mockCall<GetProfilesResponse>(mockGetProfilesResponse);
};

async function createProject(request: CreateProjectRequest): Promise<CreateProjectResponse> {
    return mockCall<CreateProjectResponse>(mockCreateProjectResponse);
};

async function getProjects(request: GetProjectsRequest): Promise<GetProjectsResponse> {
    return mockCall<GetProjectsResponse>(mockGetProjectsResponse);
};

async function deleteProject(request: DeleteProjectRequest): Promise<DeleteProjectResponse> {
    return mockCall<DeleteProjectResponse>({});
};

async function updateProject(request: UpdateProjectRequest): Promise<UpdateProjectResponse> {
    return mockCall<UpdateProjectResponse>(mockCreateProjectResponse);
};

async function deleteProfile(request: DeleteProfileRequest): Promise<DeleteProfileResponse> {
    return mockCall<DeleteProfileResponse>({profile: mockGetProfilesResponse.profiles[0]});
};

async function getProfileRecords(request: GetProfileRecordsRequest): Promise<GetProfileRecordsResponse> {
    return mockCall<GetProfileRecordsResponse>(mockGetProfileRecordsResponse);
};

async function updateProfileRecords(request: UpdateProfileRecordsRequest): Promise<UpdateProfileRecordsResponse> {
    return mockCall<UpdateProfileRecordsResponse>(mockUpdateProfileRecordsResponse);
};

async function updateProfileHeader(request: UpdateProfileHeaderRequest): Promise<UpdateProfileHeaderResponse> {
    return mockCall<UpdateProfileHeaderResponse>({profile: mockGetProfilesResponse.profiles[0]});
};

async function downloadFile(request: DownloadFileRequest): Promise<FileDownloadResponse> {
    await fetch("http://localhost:3000/index.html").then(
        (response: any) => response.blob()
    ).then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        var link = document.createElement("a"); // Or maybe get it from the current document
        link.href = url;
        link.download = request.name;
        link.innerHTML = "";
        link.click();
    });
    return {}
};

async function loadActiveProject(request: LoadActiveProjectRequest): Promise<LoadActiveProjectResponse> {
    // return mockCall<LoadActiveProjectErrors.InvalidFileFormat>(mockInvalidFileFormat);
    //return mockCall<LoadActiveProjectErrors.ParseError>(mockParsingFailed);
    return mockCall<LoadActiveProjectResponse>(mockLoadProjectResponse);
};

async function updateProjectRawRecords(request: UpdateProjectFileLinesRequest): Promise<LoadActiveProjectResponse> {
    return mockCall<LoadActiveProjectResponse>(mockLoadProjectResponse);
};

async function updateProjectRecords(request: UpdateProjectRecordsRequest): Promise<LoadActiveProjectResponse> {
    return mockCall<LoadActiveProjectResponse>(mockLoadProjectResponse);
};

async function getProjectRawRecords(request: GetRecordsRequest): Promise<GetRawRecordsResponse> {
    return mockCall<GetRawRecordsResponse>({records: mockRawRecords});
};

async function getProjectRecords(request: GetRecordsRequest): Promise<GetRecordsResponse> {
    return mockCall<GetRecordsResponse>({records: mockProjectRecords});
};

async function recalculateConversions(request: RecalculateConversionsRequest): Promise<RecalculateConversionsResponse> {
    return mockCall<RecalculateConversionsResponse>({conversions: mockLoadProjectResponse.conversions})
};

async function initiateDrawing(request: DrawRequest): Promise<InitiateDrawingResponse> {
    return mockCall<InitiateDrawingResponse>(mockDrawResponse);
};

async function getDrawingStatus(request: GetDrawingStatusRequest): Promise<GetDrawingStatusResponse> {
    return mockCall<GetDrawingStatusResponse>(mockGetDrawingStatus)
};

async function createProfile(request: CreateProfileRequest): Promise<CreateProfileResponse> {
    return mockCall<CreateProfileResponse>({profile: mockGetProfilesResponse.profiles[0]});
};

async function copyProfile(request: CopyProfileRequest): Promise<CopyProfileResponse> {
    return mockCall<CopyProfileResponse>({profile: mockGetProfilesResponse.profiles[0]});
};

async function createUser(request: CreateUserRequest): Promise<CreateUserResponse> {
    return mockCall<CreateUserResponse>({user: mockUser});
};

async function deleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse> {
    return mockCall<DeleteUserResponse>({user: mockUser});
};

async function updateUser(request: UpdateUserRequest): Promise<UpdateUserResponse> {
    return mockCall<UpdateUserResponse>({user: mockUser});
};

async function getUser(request: GetUserRequest): Promise<GetUserResponse> {
    return mockCall<GetUserResponse>({user: mockUser});
};

async function listUsers(request: ListUsersRequest): Promise<ListUsersResponse> {
    return mockCall<ListUsersResponse>({users: [mockUser, {...mockUser, id: "U2"}, {...mockUser, id: "U3", role: Role.appAdmin}]});
};

async function updateUserPassword(request: UpdateUserPasswordRequest): Promise<UpdateUserPasswordResponse> {
    return mockCall<UpdateUserPasswordResponse>({});
};

async function forgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
    return mockCall<ForgotPasswordResponse>({});;
};

async function contact(request: ContactRequest): Promise<{}> {
    return mockCall<{}>({});;
};

const client: APIClient = {
    login: login,
    setLoginInfo: setLoginInfo,
    getAppInfo: getAppInfo,
    getProjectFileUploadURL: getProjectFileUploadURL,
    uploadS3File: uploadS3File,
    getProfiles: getProfiles,
    createProfile: createProfile,
    copyProfile: copyProfile,
    createProject: createProject,
    getProjects: getProjects,
    deleteProject: deleteProject,
    updateProject: updateProject,
    deleteProfile: deleteProfile,
    getProfileRecords: getProfileRecords,
    updateProfileRecords: updateProfileRecords,
    updateProfileHeader: updateProfileHeader,
    downloadFile: downloadFile,
    loadActiveProject: loadActiveProject,
    updateProjectRawRecords: updateProjectRawRecords,
    updateProjectRecords: updateProjectRecords,
    getProjectRawRecords: getProjectRawRecords,
    getProjectRecords: getProjectRecords,
    recalculateConversions: recalculateConversions,
    initiateDrawing: initiateDrawing,
    getDrawingStatus: getDrawingStatus,
    createUser: createUser,
    deleteUser: deleteUser,
    updateUser: updateUser,
    getUser: getUser,
    listUsers: listUsers,
    updateUserPassword: updateUserPassword,
    forgotPassword: forgotPassword,
    contact: contact
};

export default client;