import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import NavItem from "./nav-item";
import { Theme } from '@mui/system';
import { panels } from "../panels";
import { Panel } from '../types';
import { useActiveProject } from '../../../state/project';
import { useLabel } from '../../../hooks';


const ActiveProject = () => {
  const activeProject = useActiveProject();
  const noProjectSelected = useLabel("labels.noActiveProjectSelected")
  const text = activeProject?.name ?? noProjectSelected;
  return <Box sx={{ minHeight: "40px", justifyContent: "center", alignItems: "center", width: "100%", pt: 2, pb: 2}}>
    <Typography align="center">{text}</Typography>
  </Box>
};

export const DashboardSidebar = (props: any) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  const content = (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(0, 0, 0, 0.96)",
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ flexGrow: 1 }} my={5}>
          {panels.map((panel: Panel) => <NavItem panel={panel} key={panel.href} />)}
        </Box>

        <Divider sx={{ borderColor: '#2D3748' }} />
        <ActiveProject />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};