import { APIException } from "./errors/errors";
import { DownloadFileRequest } from "./requests/common";
import { ContactRequest } from "./requests/contact";
import LoginRequest from "./requests/login";
import { CopyProfileRequest, CreateProfileRequest, DeleteProfileRequest, GetProfileRecordsRequest, GetProfilesRequest, UpdateProfileHeaderRequest, UpdateProfileRecordsRequest } from "./requests/profile";
import { CreateProjectRequest, DeleteProjectRequest, DrawRequest, GetDrawingStatusRequest, GetProjectsRequest, GetRecordsRequest, LoadActiveProjectRequest, ProjectFileUploadURLRequest, RecalculateConversionsRequest, UpdateProjectFileLinesRequest, UpdateProjectRecordsRequest, UpdateProjectRequest } from "./requests/project";
import { CreateUserRequest, DeleteUserRequest, ForgotPasswordRequest, GetUserRequest, ListUsersRequest, UpdateUserPasswordRequest, UpdateUserRequest } from "./requests/user";
import AppInfoResponse from "./responses/appInfo";
import { FileDownloadResponse, FileUploadResponse } from "./responses/files";
import LoginResponse from "./responses/login";
import { CopyProfileResponse, CreateProfileResponse, DeleteProfileResponse, GetProfileRecordsResponse, GetProfilesResponse, UpdateProfileHeaderResponse, UpdateProfileRecordsResponse } from "./responses/profile";
import { CreateProjectResponse, DeleteProjectResponse, GetDrawingStatusResponse, GetProjectsResponse, GetRawRecordsResponse, GetRecordsResponse, InitiateDrawingResponse, LoadActiveProjectResponse, ProjectFileUploadURLResponse, RecalculateConversionsResponse, UpdateProjectResponse } from "./responses/project";
import { CreateUserResponse, DeleteUserResponse, ForgotPasswordResponse, GetUserResponse, ListUsersResponse, UpdateUserPasswordResponse, UpdateUserResponse } from "./responses/user";
import APIClient from "./types";

class Client implements APIClient {
    apiUrl: string;
    token: string;
    companyID: string;
    userID: string;

    constructor() {
        switch (window.location.origin) {
            case "http://localhost:3001":
                this.apiUrl = "https://api.dev.octopo.be/";
                break;
            case "http://plan2-frontend-bucket-dev.s3-website-eu-west-1.amazonaws.com":
                this.apiUrl = "https://api.dev.octopo.be/";
                break;
            case "http://plan2-frontend-bucket-prd.s3-website-eu-west-1.amazonaws.com":
                this.apiUrl = "https://api.octopo.be/";
                break;
            case "http://localhost:3002":
                this.apiUrl = "https://api.octopo.be/";
                break;
            case "https://dev.octopo.be":
                this.apiUrl = "https://api.dev.octopo.be/";
                break;
            case "https://dev.octopo.nl":
                this.apiUrl = "https://api.dev.octopo.be/";
                break;
            case "http://web:3000":
                this.apiUrl = "https://api.dev.octopo.be/";
                break;
            case "https://octopo.be":
                this.apiUrl = "https://api.octopo.be/";
                break;
            case "https://octopo.nl":
                this.apiUrl = "https://api.octopo.be/";
                break;
            default:
                this.apiUrl = "https://api.octopo.be/";
        };
        this.token = "";
        this.companyID = "";
        this.userID = "";
    }

    setLoginInfo(token: string, companyID: string, userID: string): void {
        this.token = token;
        this.companyID = companyID;
        this.userID = userID;
    }
    async makeRequest<Request extends object, Response>(method: string, path: string, body: Request): Promise<Response> {
        let headers: any = {};
        if (this.token !== "") {
            headers["Authorization"] = `Bearer ${this.token}`
        };
        if (method === "GET") {
            path = `${path}?${Object.keys(body).map((key: string) => {
                return `${key}=${encodeURIComponent(String(body[key as keyof typeof body]))}`;
            }).join("&")}`  
        };
        const doRequest = async () => {
            const response = await window.fetch(this.apiUrl + path, {
                method: method,
                headers: headers,
                body: method !== "GET" ? JSON.stringify(body) : undefined
            });
            const data = await response.json();
            return [response, data]
        };
        var [response, data] = [undefined, undefined] as any;
        try {
            [response, data] = await doRequest();
            if (!response.ok) {
                throw Error()
            }
        } catch {
            [response, data] = await doRequest();
        }

        console.log(data)
        if (response.ok) {
            return data
        } else {
            if (response.status === 401) {
                // Logout
                window.localStorage.clear();
                window.location.reload()
            }
            console.error(data);
            throw new APIException(response.status, data["detail"]["errorCode"], data["detail"]["errorParams"]);
            // throw APIException(response)
        }
    };

    async login(request: LoginRequest): Promise<LoginResponse> {
        const method = "POST";
        const path = "login";
        return this.makeRequest<LoginRequest, LoginResponse>(
            method,
            path,
            request
        ).then((data: LoginResponse) => {
            this.setLoginInfo(
                data?.token,
                data?.company.id,
                data?.user.id
            );
            return data;
            }
        );
    };

    async getAppInfo(): Promise<AppInfoResponse> {
        const method = "GET";
        const path = "info";
        return await this.makeRequest<any, AppInfoResponse>(method, path, {});
    };

    async getProjectFileUploadURL(request: ProjectFileUploadURLRequest): Promise<ProjectFileUploadURLResponse> {
        const method = "GET";
        const path = "project/file-upload-url";
        return await this.makeRequest<ProjectFileUploadURLRequest, ProjectFileUploadURLResponse>(method, path, request);
    };

    async uploadS3File(file: File, url: ProjectFileUploadURLResponse, content: string): Promise<FileUploadResponse> {
        async function upload(url: ProjectFileUploadURLResponse, file: File) {

            const response = await fetch(url.url, {
              method: "PUT",
              body: new Blob([file])
            });
            return response;
        }

        return await upload(url, file);
    };

    async getProfiles(request: GetProfilesRequest): Promise<GetProfilesResponse> {
        const method = "GET";
        const path = "profiles";
        return await this.makeRequest<GetProfilesRequest, GetProfilesResponse>(method, path, request);
    };

    async createProfile(request: CreateProfileRequest): Promise<CreateProfileResponse> {
        const method = "POST";
        const path = "profile";
        return await this.makeRequest<CreateProfileRequest, CreateProfileResponse>(method, path, request);
    };

    async copyProfile(request: CopyProfileRequest): Promise<CopyProfileResponse> {
        const method = "POST";
        const path = "profile/copy";
        return await this.makeRequest<CopyProfileRequest, CopyProfileResponse>(method, path, request);
    };

    async createProject(request: CreateProjectRequest): Promise<CreateProjectResponse> {
        const method = "POST";
        const path = "project";
        return await this.makeRequest<CreateProjectRequest, CreateProjectResponse>(method, path, request);
    };

    async getProjects(request: GetProjectsRequest): Promise<GetProjectsResponse> {
        const method = "GET";
        const path = "projects";
        return await this.makeRequest<GetProjectsRequest, GetProjectsResponse>(method, path, request);
    };

    async deleteProject(request: DeleteProjectRequest): Promise<DeleteProjectResponse> {
        const method = "DELETE";
        const path = "project";
        return await this.makeRequest<DeleteProjectRequest, DeleteProjectResponse>(method, path, request);
    };

    async updateProject(request: UpdateProjectRequest): Promise<UpdateProjectResponse> {
        const method = "PUT";
        const path = "project";
        return await this.makeRequest<UpdateProjectRequest, UpdateProjectResponse>(method, path, request);
    };

    async deleteProfile(request: DeleteProfileRequest): Promise<DeleteProfileResponse> {
        const method = "DELETE";
        const path = "profile";
        return await this.makeRequest<DeleteProfileRequest, DeleteProfileResponse>(method, path, request);
    };

    async getProfileRecords(request: GetProfileRecordsRequest): Promise<GetProfileRecordsResponse> {
        const method = "GET";
        const path = "profile/records";
        return await this.makeRequest<GetProfileRecordsRequest, GetProfileRecordsResponse>(method, path, request);
    };

    async updateProfileRecords(request: UpdateProfileRecordsRequest): Promise<UpdateProfileRecordsResponse> {
        const method = "PUT";
        const path = "profile/records";
        return await this.makeRequest<UpdateProfileRecordsRequest, UpdateProfileRecordsResponse>(method, path, request);
    };

    async updateProfileHeader(request: UpdateProfileHeaderRequest): Promise<UpdateProfileHeaderResponse> {
        const method = "PUT";
        const path = "profile";
        return await this.makeRequest<UpdateProfileHeaderRequest, UpdateProfileHeaderResponse>(method, path, request);
    };

    async downloadFile(request: DownloadFileRequest): Promise<FileDownloadResponse> {
        await fetch(request.url).then(
            (response: any) => response.blob()
        ).then((blob: Blob) => {
            const url = URL.createObjectURL(blob);
            var link = document.createElement("a"); // Or maybe get it from the current document
            link.href = url;
            link.download = request.name;
            link.innerHTML = "";
            link.click();
        });
        return {}
    };

    async loadActiveProject(request: LoadActiveProjectRequest): Promise<LoadActiveProjectResponse> {
        const method = "POST";
        const path = "project/load";
        return await this.makeRequest<LoadActiveProjectRequest, LoadActiveProjectResponse>(method, path, request);
    };

    async updateProjectRecords(request: UpdateProjectRecordsRequest): Promise<LoadActiveProjectResponse> {
        const method = "PUT";
        const path = "project/records";

        return await this.makeRequest<UpdateProjectRecordsRequest, LoadActiveProjectResponse>(method, path, request);
    };

    async updateProjectRawRecords(request: UpdateProjectFileLinesRequest): Promise<LoadActiveProjectResponse> {
        const method = "PUT";
        const path = "project/raw-records";
        return await this.makeRequest<UpdateProjectFileLinesRequest, LoadActiveProjectResponse>(method, path, request);
    };

    async getProjectRawRecords(request: GetRecordsRequest): Promise<GetRawRecordsResponse> {
        const method = "GET";
        const path = "project/raw-records";
        return await this.makeRequest<GetRecordsRequest, GetRawRecordsResponse>(method, path, request);
    };

    async getProjectRecords(request: GetRecordsRequest): Promise<GetRecordsResponse> {
        const method = "GET";
        const path = "project/records";
        return await this.makeRequest<GetRecordsRequest, GetRecordsResponse>(method, path, request);
    };

    async recalculateConversions(request: RecalculateConversionsRequest): Promise<RecalculateConversionsResponse> {
        const method = "POST";
        const path = "project/conversions";
        return await this.makeRequest<RecalculateConversionsRequest, RecalculateConversionsResponse>(method, path, request);
    };

    async initiateDrawing(request: DrawRequest): Promise<InitiateDrawingResponse> {
        const method = "POST";
        const path = "project/draw";
        return await this.makeRequest<DrawRequest, InitiateDrawingResponse>(method, path, request);
    };

    async getDrawingStatus(request: GetDrawingStatusRequest): Promise<GetDrawingStatusResponse> {
        const method = "GET";
        const path = "project/draw";
        return await this.makeRequest<GetDrawingStatusRequest, GetDrawingStatusResponse>(method, path, request);
    };

    async createUser(request: CreateUserRequest): Promise<CreateUserResponse> {
        const method = "POST";
        const path = "user";
        return await this.makeRequest<CreateUserRequest, CreateUserResponse>(method, path, request);
    };

    async deleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse> {
        const method = "DELETE";
        const path = "user";
        return await this.makeRequest<DeleteUserRequest, DeleteUserResponse>(method, path, request);
    };

    async updateUser(request: UpdateUserRequest): Promise<UpdateUserResponse> {
        const method = "PUT";
        const path = "user";
        return await this.makeRequest<UpdateUserRequest, UpdateUserResponse>(method, path, request);
    };

    async getUser(request: GetUserRequest): Promise<GetUserResponse> {
        const method = "GET";
        const path = "user";
        return await this.makeRequest<GetUserRequest, GetUserResponse>(method, path, request);
    };

    async listUsers(request: ListUsersRequest): Promise<ListUsersResponse> {
        const method = "GET";
        const path = "users";
        return await this.makeRequest<ListUsersRequest, ListUsersResponse>(method, path, request);
    };

    async updateUserPassword(request: UpdateUserPasswordRequest): Promise<UpdateUserPasswordResponse> {
        const method = "PUT";
        const path = "user/password";
        if (request.token !== undefined) {
            this.token = request.token
        };
        const response = await this.makeRequest<UpdateUserPasswordRequest, UpdateUserPasswordResponse>(method, path, request);
        if (request.token !== undefined) {
            this.token = ""
        };
        return response;
    };

    async forgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
        const method = "POST";
        const path = "user/forgot-password";
        return await this.makeRequest<ForgotPasswordRequest, ForgotPasswordResponse>(method, path, request);
    };

    async contact(request: ContactRequest): Promise<{}> {
        const method = "POST";
        const path = "contact";
        return await this.makeRequest<ContactRequest, {}>(method, path, request);
    };
}

const client: APIClient = new Client();

export default client;