import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLabel } from "../../hooks";
import { useState } from "react";

const PageControls = (props: {nbPages: number, pageNumber: number, setPageNumber: any}) => {
    const decrementPage = () => {
        if (props.pageNumber <= 0) {
            props.setPageNumber(0);
        } else {
            props.setPageNumber(props.pageNumber - 1);
        }
    }
    const incrementPage = () => {
        if (props.pageNumber >= props.nbPages - 1) {
            props.setPageNumber(props.nbPages - 1);
        } else {
            props.setPageNumber(props.pageNumber + 1);
        }
    }

    return <Box sx={{
        display: props.nbPages <= 1 ? "none" : "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        flexDirection: "row"
    }}>
        <IconButton onClick={decrementPage}>
            <ArrowBackIcon/>
        </IconButton>
        <Box><Typography>{props.pageNumber + 1} / {props.nbPages}</Typography></Box>
        <IconButton onClick={incrementPage}>
            <ArrowForwardIcon/>
        </IconButton>
    </Box>
};

const SaveButton = (props: { onSave: any, hasChanged: boolean }) => {
    const saveLabel = useLabel("labels.save");
    const tooltipLabel = useLabel("modifyData.requireChange")
    const [saving, setSaving] = useState(false);

    return <Box sx={{ minWidth: "100px" }}>
        <Tooltip disableHoverListener={props.hasChanged} title={tooltipLabel}>
            <span> {/* Span is required for tooltip to work */}
                <Button 
                    disabled={saving || !props.hasChanged}
                    variant="contained"
                    onClick={() => {
                        setSaving(true);
                        props.onSave();
                        setSaving(false);
                }}>
                    {saveLabel}
                </Button>
            </span>
        </Tooltip>
    </Box>
};

const Buttons = (props: {onDiscard: any, onSave: any, hasChanged: boolean}) => {
    return <Box sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        flexDirection: "row",
        mt: 2
    }}>
        <SaveButton onSave={props.onSave} hasChanged={props.hasChanged}/>
    </Box>
};

const TableControls = (props: {
    hasChanged: boolean,
    pageNumber: number, 
    setPageNumber: any, 
    nbPages: number,
    onDiscard: any,
    onSave: any
}) => {

    return <Box>
        <PageControls pageNumber={props.pageNumber} setPageNumber={props.setPageNumber} nbPages={props.nbPages} />
        <Buttons hasChanged={props.hasChanged} onDiscard={props.onDiscard} onSave={props.onSave}/>
    </Box>
};

export default TableControls;