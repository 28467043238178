import { useContext, useState } from "react";
import { 
    Box,
    Button,
    Checkbox, 
    FormControl, 
    FormControlLabel,
    FormGroup,
    InputLabel, 
    MenuItem, 
    Typography, 
    Select,
    Switch
} from "@mui/material";
import { useLabel } from "../../../../hooks";
import { TextInput, S3FileUpload } from "../../../../components";
import { ProjectType, DrawSettings} from "../../../../types/project";
import { SelectedFile } from "../../../../components/S3FileUpload";
import { defaultUploadFile, defaultDrawSettings } from "../../../../data/defaultValues";
import { ProfileHeader } from "../../../../types/profile";
import { useProfiles } from "../../../../state/profile";
import { selectCompany } from "../../../../state/auth";
import { createProject } from "./createProject";
import Container from "../../../../components/PaperContainer";
import { useRecoilValue } from "recoil";
import { APIStateContext } from "../../../../state/context";
import { useNavigate } from "react-router-dom";


const Subtitle = (props: { labelKey: string }) => {
    const label = useLabel(props.labelKey);

    return <Typography variant="h4" style={{marginBottom: 20, marginTop: 30}}>{label}</Typography>
};

export const NameInput = (props: {name: string, setName: any}) => {
    return <>
        <Subtitle labelKey={"projectConfig.name"} />
        <TextInput
            label={useLabel("projectConfig.name")}
            error={false}
            onChange={(event: any) => props.setName(event.target.value)}
            value={props.name}
            type="string"
        />
    </>
}

export const ProjectTypeCheckbox = (props: {
        selectedType: ProjectType,
        checkboxType: ProjectType,
        label: string,
        setType: any,
        disabled: boolean | undefined
    }) => {
        const onChange = (event: any, targetProjectType: ProjectType) => {
            if (event.target.checked) {
                props.setType(targetProjectType);
            }
        }

        return <FormControlLabel 
            label={props.label}
            control={<Checkbox
                disabled={props.disabled ?? false}
                checked={props.selectedType == props.checkboxType}
                onChange={(event: any) => onChange(event, props.checkboxType)}
            />}
        />
    }

export const TypeInput = (props: {type: ProjectType, setType: any, disabled?: boolean | undefined}) => {
    const gpsLabel = useLabel("projectConfig.gps");
    const localLabel = useLabel("projectConfig.local");

    return <>
        <Subtitle labelKey="projectConfig.type" />
        <ProjectTypeCheckbox 
            selectedType={props.type}
            checkboxType={ProjectType.GPS}
            label={gpsLabel}
            setType={props.setType}
            disabled={props.disabled}
        />
        <ProjectTypeCheckbox 
            selectedType={props.type}
            checkboxType={ProjectType.LOCAL}
            label={localLabel}
            setType={props.setType}
            disabled={props.disabled}
        />
    </>
}

export const FileUpload = (props: {
    projectType: ProjectType,
    csvFile?: SelectedFile | null,
    gpsFile?: SelectedFile | null,
    tmfFile?: SelectedFile | null,
    setCsvFile: any,
    setTmfFile: any,
    setGpsFile: any
}) => {
    
    // client-side filter? 
    const extensions = props.projectType == ProjectType.GPS ? (
        [".csv", ".obj"]
    ) : (
        [".tmf", ".gps", ".obj"]
    );
    return <>
        <Subtitle labelKey="projectConfig.files" />
        <Box sx={{ display : props.projectType == ProjectType.GPS ? "block" : "none" }}>
            <S3FileUpload
                labelKey="projectConfig.csv"
                extensions={[".csv", ".obj"]}
                file={props.csvFile}
                setFile={props.setCsvFile}
            /> 
        </Box>
        <Box sx={{ display : props.projectType == ProjectType.LOCAL ? "block" : "none" }}>
            <S3FileUpload
                labelKey="projectConfig.tmf"
                extensions={[".tmf", ".obj"]}
                file={props.tmfFile}
                setFile={props.setTmfFile}
            />
            <S3FileUpload
                labelKey="projectConfig.gpsFile"
                extensions={[".gps", ".obj"]}
                file={props.gpsFile}
                setFile={props.setGpsFile}
            />
        </Box>
    </>
}

export const ProfileInput = (props: {profileID: string | null, setProfileID: any}) => {
    const profiles = useProfiles();
    const label = useLabel("projectConfig.profile");

    if (profiles.length != 0 && props.profileID == null) {
        props.setProfileID(profiles[0].id);
    };
    return <>
        <Subtitle labelKey="projectConfig.profile" />
        <FormControl>
            <InputLabel htmlFor="profile">{label}</InputLabel>
            <Select
                value={props.profileID}
                id="profile"
                label={label}
                onChange={(event: any) => props.setProfileID(event.target.value)}
            >
                {profiles.map((profile: ProfileHeader) => <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>)}
            </Select>
        </FormControl>
    </>
};

export const DrawSettingsInput = (props: {drawSettings: DrawSettings, setDrawSettings: any}) => {
    const [isValidNumber, setIsValidNumber] = useState(true);
    const pointLayerLabel = useLabel("projectConfig.point_layer");
    return <>
        <Subtitle labelKey="projectConfig.draw" />
        <TextInput 
            label={useLabel("projectConfig.ppd1")}
            error={!isValidNumber}
            onChange={(event: any) => {
                if (isNaN(event.target.value) || event.target.value === "") {
                    setIsValidNumber(false);
                } else {
                    setIsValidNumber(true);
                }
                props.setDrawSettings({...props.drawSettings, ppd1: event.target.value});
            }}
            value={props.drawSettings.ppd1.toString()}
            type="string"
        />
        <TextInput 
            label={useLabel("projectConfig.p_layer")}
            error={false}
            onChange={(event: any) => {
                props.setDrawSettings({...props.drawSettings, p_layer: event.target.value});
            }}
            value={props.drawSettings.p_layer}
            type="string"
        />
        <TextInput 
            label={useLabel("projectConfig.q_layer")}
            error={false}
            onChange={(event: any) => {
                props.setDrawSettings({...props.drawSettings, q_layer: event.target.value});
            }}
            value={props.drawSettings.q_layer}
            type="string"
        />
        <TextInput 
            label={useLabel("projectConfig.height_block_prefix")}
            error={false}
            onChange={(event: any) => {
                props.setDrawSettings({...props.drawSettings, height_block_prefix: event.target.value});
            }}
            value={props.drawSettings.height_block_prefix}
            type="string"
        />
        <TextInput 
            label={useLabel("projectConfig.height_block_attribute")}
            error={false}
            onChange={(event: any) => {
                props.setDrawSettings({...props.drawSettings, height_block_attribute: event.target.value});
            }}
            value={props.drawSettings.height_block_attribute}
            type="string"
        />
        <FormGroup>
            <FormControlLabel control={
                <Switch 
                    checked={props.drawSettings.point_layer === null} 
                    onChange={(event, checked) => {
                        if (checked) {
                            props.setDrawSettings(
                                {
                                    ...props.drawSettings, 
                                    point_layer: null
                                }
                            )
                        } else {
                            props.setDrawSettings(
                                {
                                    ...props.drawSettings, 
                                    point_layer: defaultDrawSettings.point_layer
                                }
                            )
                        }
                    }}
                />        
            } label={useLabel("projectConfig.points_in_layer")} />
        
        {
            props.drawSettings.point_layer ? <TextInput 
                label={pointLayerLabel}
                error={false}
                onChange={(event: any) => {
                    props.setDrawSettings({...props.drawSettings, point_layer: event.target.value});
                }}
                value={props.drawSettings.point_layer}
                type="string"
            /> : null
        }
        </FormGroup>
        <FormGroup>
            <FormControlLabel control={
                <Switch 
                    checked={props.drawSettings.is_3d} 
                    onChange={() => {
                        props.setDrawSettings(
                            {
                                ...props.drawSettings, 
                                is_3d: !props.drawSettings.is_3d, 
                                polylines_3d: props.drawSettings.polylines_3d && !props.drawSettings.is_3d
                            }
                        )}
                    }
                />
                        
            } label={useLabel("projectConfig.is3D")} />
            <FormControlLabel control={
                <Switch 
                    checked={props.drawSettings.polylines_3d} 
                    disabled={!props.drawSettings.is_3d}
                    onChange={() => props.setDrawSettings(
                        {...props.drawSettings, polylines_3d: !props.drawSettings.polylines_3d}
                    )}
                />
                        
            } label={useLabel("projectConfig.polylines3D")} />
        </FormGroup>
    </>
};

export const CreateButton = (props: {onClick: any}) => {
    const label = useLabel("projectConfig.create")
    return <Box mt={4}>
        <Button onClick={props.onClick} variant="contained">{label}</Button>
    </Box>
};

export const Feedback = (props: {feedback: string}) => {
    return <Typography>{props.feedback}</Typography>
};

const NewProject = (props: any) => {
    const company = useRecoilValue(selectCompany);
    const [projectName, setProjectName] = useState("");
    const [projectType, setProjectType] = useState(ProjectType.GPS);
    const [profileID, setProfileID] = useState("");
    const [drawSettings, setDrawSettings] = useState(defaultDrawSettings);
    useGetDefaultDrawSettings(profileID, setDrawSettings);
    const [csvFile, setCsvFile] = useState(defaultUploadFile);
    const [tmfFile, setTmfFile] = useState(defaultUploadFile);
    const [gpsFile, setGpsFile] = useState(defaultUploadFile);
    const fileUploadProps = {
        csvFile, setCsvFile, tmfFile, setTmfFile, gpsFile, setGpsFile, projectType
    }
    const [feedback, setFeedback] = useState("");

    const useOnValidationError = (labelKey: string) => {
        setFeedback(useLabel(labelKey));
    };
    const api = useContext(APIStateContext);
    const navigate = useNavigate();
    
    return <Container>
        <NameInput name={projectName} setName={setProjectName} />
        <TypeInput type={projectType} setType={setProjectType} />
        <FileUpload {...fileUploadProps}/>
        <ProfileInput profileID={profileID} setProfileID={setProfileID}/>
        <DrawSettingsInput drawSettings={drawSettings} setDrawSettings={setDrawSettings}/>
        <CreateButton onClick={
            async () => {
                const project = await createProject(
                    projectName, 
                    projectType, 
                    csvFile,
                    tmfFile,
                    gpsFile,
                    drawSettings,
                    profileID,
                    company.id,
                    useOnValidationError,
                    api
                );

                if (project !== undefined) {
                    navigate("/dashboard/modify-data");
                };
            }
        } />
        <Feedback feedback={feedback} />
    </Container>

};

function useGetDefaultDrawSettings(profileID: string, setDrawSettings: any) {
    const [lastSetProfile, setLastSetProfile] = useState("");

    const selectedProfile = useProfiles().filter((profile: ProfileHeader) => profile.id === profileID);
    if (selectedProfile.length > 0 && profileID !== lastSetProfile) {
        const profile = selectedProfile[0];
        if (profile.name.toLowerCase().includes("plan priv")) {
            setDrawSettings({...defaultDrawSettings, p_layer: "gevel", q_layer: "gevel"})
            setLastSetProfile(profileID);
        } else if (profile.name.toLowerCase().includes("grb")) {
            setLastSetProfile(profileID);
            setDrawSettings({...defaultDrawSettings, p_layer: "GVL6", q_layer: "GVL6"})
        }
    };
    return defaultDrawSettings;
}

export default NewProject;
