import React from "react";
import { Panel } from "../types";
import NewProject from "./new-project";
import ExistingProjects from "./existing-projects";
import Modify from "./modify";
import ModifyData from "./modify-data"
import Draw from "./draw"
import NewProfile from "./new-profile";
import ExistingProfiles from "./existing-profiles";
import Info from "./info";

import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FolderIcon from '@mui/icons-material/Folder';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TocIcon from '@mui/icons-material/Toc';
import MapIcon from '@mui/icons-material/Map';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import InfoIcon from '@mui/icons-material/Info';
import Convert from "./convert";

const panels: Array<Panel> = [
  {
    label: "projects.new",
    href: "/dashboard/new-project",
    component: <NewProject />,
    icon: <NoteAddIcon />
  },
  {
    label: "projects.existing",
    href: "/dashboard/existing-projects",
    component: <ExistingProjects />,
    icon: <FolderIcon />
  },
  {
    label: "activeProject.modify",
    href: "/dashboard/modify",
    component: <Modify />,
    icon: <AutoFixHighIcon />
  },
  {
    label: "activeProject.modifyData",
    href: "/dashboard/modify-data",
    component: <ModifyData />,
    icon: <TocIcon />
  },
  {
    label: "activeProject.convert",
    href: "/dashboard/convert",
    component: <Convert />,
    icon: <MapIcon />
  },
  {
    label: "draw.draw",
    href: "/dashboard/draw",
    component: <Draw />,
    icon: <ArchitectureIcon />
  },
  {
    label: "profiles.new",
    href: "/dashboard/new-profile",
    component: <NewProfile />,
    icon: <PersonAddIcon />
  },
  {
    label: "profiles.existing",
    href: "/dashboard/existing-profiles",
    component: <ExistingProfiles />,
    icon: <GroupIcon />
  },
  {
    label: "info",
    href: "/dashboard/info",
    component: <Info />,
    icon: <InfoIcon />
  }
]

export default panels;