import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';
import { useLabel } from "../../../hooks";
import { Panel } from "../types";
import { Link } from "react-router-dom";

interface NavItemProps {
  panel: Panel
}

export const NavItem = (props: NavItemProps) => {
  const [active, setActive] = useState(false); // todo

  useEffect(() => {
    if (window.location.href.endsWith(props.panel.href)) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <ListItem
      disableGutters
      key={props.panel.href}
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
    >
      <Link to={props.panel.href} style={{ textDecoration: 'none', width: "100%" }}>
        <Button
          startIcon={props.panel.icon}
          disableRipple
          /*
          // @ts-ignore */
          sx={{
            backgroundColor: active ? 'rgba(255,255,255, 0.04)' : 'rgba(255, 255, 255, 0.08)',
            borderRadius: 1,
            color: active ? 'secondary.main' : 'rgb(255, 255, 255, 0.50)',
            fontWeight: active && 'fontWeightBold',
            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.400'
            },
            '&:hover': {
              color: 'secondary.main',
              backgroundColor: 'rgba(255, 255, 255, 0.04)'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {useLabel(props.panel.label)}
          </Box>
        </Button>
      </ Link>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string
};

export default NavItem;