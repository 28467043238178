import { Language } from "../../language"
import { ParseResult, ProjectFileLinesParseResult } from "../../types/project"


export enum ParseErrorType {
    INVALID_FILE = "INVALID_FILE",
    RAW_PARSING_FAILED = "RAW_PARSING_FAILED",
    PARSING_FAILED = "PARSING_FAILED",
    VALIDATION_FAILED = "VALIDATION_FAILED",
    CONVERSIONS_FAILED = "CONVERSIONS_FAILED"
};

export namespace LoadActiveProjectErrors {
    export interface InvalidFileFormat {
        // Failed format of a line
        parse_error: ParseErrorType
        parse_result: ProjectFileLinesParseResult
    };

    export interface ParseError {
        // Failed to profile the records
        parse_error: ParseErrorType
        parse_result: ParseResult
    };
};

export class APIException extends Error {
    statusCode: number;
    errorCode: string;
    errorParams: any;

    constructor(statusCode: number, errorCode: string, errorParams: any) {
        super();
        this.statusCode = statusCode;
        this.errorCode = errorCode;
        this.errorParams = errorParams;
    };

    format(language: Language): string {
        const serverErrors = language.error.server;
        if (this.errorCode in (serverErrors as any)) {

            return (serverErrors as any)[this.errorCode](this.errorParams);
        } else {
            return serverErrors.unknown();
        }
    }
}