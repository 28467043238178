import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { useLabel } from "../../hooks";

const ConfirmationDialog = (
    props: {
        open: boolean,
        setOpen: any,
        onConfirm: any, 
        onCancel?: any, 
        textLabelKey: string, 
        confirmLabelKey?: string, 
        cancelLabelKey?: string
    }
) => {
  
    const handleConfirm = (event: any) => {
        event.stopPropagation();
        props.onConfirm(event);
        props.setOpen(false);
    };

    const handleClose = (event: any) => {
        event.stopPropagation();
        (props.onCancel ?? ((event: any) => {}))(event)
        props.setOpen(false);
    };

    const text = useLabel(props.textLabelKey);
    const confirm = useLabel(props.confirmLabelKey ?? "labels.confirm");
    const cancel = useLabel(props.cancelLabelKey ?? "labels.cancel");

    return <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            {text}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleConfirm} variant="contained">{confirm}</Button>
        <Button onClick={handleClose} variant="outlined">{cancel}</Button>
        </DialogActions>
    </Dialog>
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

export default ConfirmationDialog;