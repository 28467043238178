import { Box, Paper } from "@mui/material";

const PaperContainer = (props: { children: any, fill?: boolean }) => {
    const { children } = props;
    return <Box 
        sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: props.fill ? "100vh" : undefined,
            flexDirection: "column",
            marginTop: 8,
            marginBottom: 8,
            maxWidth: "100%"
        }}
    >
        <Paper
            elevation={3}
            sx={{
                alignItems: 'left',
                display: 'flex',
                minWidth: "80%",
                maxWidth: "100%",
                flexDirection: "column",
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8
            }}>
            {children}
        </Paper>
    </Box>
};

export default PaperContainer;