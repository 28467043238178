import Language from "./types";

const nl: Language = {
    forgotPassword: {
        feedback: (email: string) => `Er werd een email gestuurd naar ${email}. Indien u de mail niet ontvangt, check uw spam folder en controleer dat het account bestaat. Klik op het logo om terug te gaan.`,
        label: "Wachtwoord vergeten",
        resetComplete: "Uw wachtwoord werd opnieuw ingesteld. Klik op het logo om opnieuw naar het loginscherm te gaan.",
        reset: "Wachtwoord opnieuw instellen"
    },
    userLabels: {
        create: "Nieuwe gebruiker",
        name: "Gebruiker",
        email: "Email",
        role: "Rol",
        passwordShort: "Wachtwoord te kort",
        passwordsDontMatch: "Wachtwoorden komen niet overeen",
        newPassword: "Nieuw wachtwoord",
        changePassword: "Wachtwoord wijzigen",
        passwordChanged: "Wachtwoord gewijzigd",
        manage: "Gebruikers beheren"
    },
    projects: {
        new: "Nieuw Project",
        existing: "Bestaande projecten",
        delete: "Project verwijderen",
        creating: "Project wordt aangemaakt. Even geduld...",
        loading: "Project wordt geladen. Even geduld..."
    },
    activeProject: {
        modify: "Bewerk Actief Project",
        modifyData: "Gegevens bewerken",
        convert: "Converteren"
    },
    draw: {
        draw: "Tekenen",
        preview: "Preview",
        drawing: "Uw tekening wordt gegenereerd. Even geduld..."
    },
    profiles: {
        new: "Nieuw Profiel",
        existing: "Profielen"
    },
    info: "Informatie",
    labels: {
        email: "Emailadres",
        password: "Wachtwoord",
        confirmPassword: "Wachtwoord bevestigen",
        signIn: "Aanmelden",
        settings: "Instellingen",
        logout: "Uitloggen",
        contact: "Contact",
        contactMessagePlaceholder: "Typ hier uw bericht...",
        send: "Verzenden",
        author: "©",
        version: "Versie",
        chooseFile: "Kies bestanden",
        upload: "Upload",
        refresh: "Refresh",
        noActiveProjectSelected: "Geen actief project geselecteerd",
        save: "Opslaan",
        discard: "Wijzigingen ongedaan maken",
        create: "Aanmaken",
        delete: "Verwijderen",
        download: "Downloaden",
        confirmDelete: "Weet u zeker dat u dit item wil verwijderen?",
        confirm: "Bevestigen",
        cancel: "Annuleren",
        convertNotApplicable: "Conversie is niet van toepassing op dit project."
    },
    convert: {
        conversion: "Conversie",
        usePoint: "Punt gebruiken",
        localPoint: "Lokaal punt",
        GPSPoint: "GPS punt",
        calculatedPoint: "Berekend punt",
        tolerance: "Tolerantie",
        deviation: "Afwijking",
        recalculate: "Herberekenen",
        pointNumber: "Puntnummer",
        exportPDF: "Exporteer PDF",
        exportKGSLocal: "Exporteer KGS lokaal",
        exportKGSGPS: "Exporteer KGS GPS",
        origin: (origin_point_number: string) => `Standpunt: ${origin_point_number}`,
        gps: "GPS coördinaten"
    },
    projectConfig: {
        name: "Naam van het project",
        type: "Type project",
        profile: "Profiel",
        gps: "GPS coördinaten (.csv)",
        local: "Lokaal project (.tmf of .gsi, .gps)",
        csv: "CSV bestand",
        tmf: "TMF bestand",
        gpsFile: "GPS bestand",
        files: "Kies bestanden",
        draw: "Tekeninstellingen",
        ppd1: "PPD1 variabele (excentriciteit P en Q)",
        is3D: "Teken in 3D",
        polylines3D: "Polylines in 3D",
        p_layer: "Naam van de layer voor excentriciteit P",
        q_layer: "Naam van de layer voor excentriciteit Q",
        points_in_layer: "Teken puntblocks in layer van de objectcode",
        point_layer: "Layer voor puntblocks",
        height_block_prefix: "Prefix voor layers met hoogtepeil",
        height_block_attribute: "Attribuut voor voor hoogtepeil (laat leeg voor default)",
        create: "Aanmaken",
        update: "Wijzigen",
        created: "aangemaakt"
    },
    existingProjects: {
        name: "Projectnaam",
        creationTimestamp: "Gemaakt op",
        updateTimestamp: "Laatste wijziging",
        projectType: "Project Type",
        projectTypeMappings: {
            LOCAL_PROJECT: "Lokaal project",
            GPS_COORDINATES: "GPS-Coördinaten"
        }
    },
    existingProfiles: {
        name: "Profielnaam",
        symbolsFileName: "Symbolenbestand",
        creationTimestamp: "Gemaakt op",
        updateTimestamp: "Laatste wijziging",
    },
    profileConfig: {
        name: "Profielnaam",
        selectCreateType: "Selecteer hoe u het profiel wil creëren",
        copy: "Kopieer een bestaand profiel",
        upload: "Upload bestanden",
        empty: "Creëer nieuw object-bestand",
        existing: "Selecteer het bestaand profiel",
        uploadObj: "Importeer een OBJ-bestand",
        uploadSymbols: "Importeer een symbolen bestand (.DWG)",
        editProfileHeader: "Bewerk Profiel",
        editProfileRecords: "Bewerk gegevens",
        objectCodeLength: "Lengte van de object code"
    },
    modifyData: {
        insert: "Rij dupliceren",
        delete: "Rij verwijderen",
        code: "Objectcode",
        layer: "Layer",
        color: "Kleur",
        symbol_name: "Symbool",
        line_type: "Lijntype",
        orientation: "Orientatie",
        scale: "Schaal",
        description: "Beschrijving",
        requireChange: "Maak een wijziging alvorens op te slaan",
        export: "Exporteren",
        rawLabels: {
            line: "Lijn",
            object_code: "Objectcode",
            excentricity: "Excentriciteit",
            line_code: "Lijncode",
            line_number: "Lijnnummer",
            attributes: "Attributen",
            sequence_number: "Volgnummer",
            point_number: "Puntnummer",
            coordinate_x: "Coördinaat X",
            coordinate_y: "Coördinaat Y",
            coordinate_z: "Coördinaat Z",
            info: "Puntinfo",
            distance: "Afstand",
            horizontal_angle: "Horizontale Hoek",
            vertical_angle: "Verticale Hoek",
            setup_height: "Opstelhoogte",
            point_id: "Punt code",
            identification_code: "Identificatiecode",
            unknown: "Onbekend veld"
        }
    },
    error: {
        client: {
            invalidFile: "Ongeldige bestanden geselecteerd"
        },
        server: {
            drawing_failed: () => "Tekenen van het bestand is niet gelukt.",
            unauthorized: () => "U hebt geen toegang tot deze actie",
            token_expired: () => "Toegang verlopen, gelieve opnieuw in te loggen",
            invalid_input: () => "Ongeldige waarde",
            invalid_info: ({info, object_code_length}: {info: string, object_code_length: number}) => `De info ${info} kon niet worden verwerkt. Dit kan komen door een ongeldig profiel. Verwachte lengte van de object code: ${object_code_length}`,
            not_found: () => "Niet gevonden",
            user_exists: () => "Er bestaat reeds een gebruiker met dit email-adres",
            invalid_login: () => "Email en wachtwoord ongeldig",
            update_unexisting_profile_record: ({record_id}: {record_id: string}) => `Geen record gevonden met ID ${record_id}`,
            update_unexisting_project_record: ({record_id}: {record_id: string}) => `Geen record gevonden met ID ${record_id}`,
            update_unexisting_file_line: ({record_id}: {record_id: string}) => `Geen record gevonden met ID ${record_id}`,
            missing_csv_file: () => "CSV-bestand niet gevonden",
            missing_tmf_file: () => "TMF-bestand niet gevonden",
            name_too_short: () => "De naam van het project moet tenminste 3 karakters bevatten",
            project_raw_parsing_failed: ({raw_parse_result}: {raw_parse_result: any}) => `Verwerken van het bestand niet geslaagd. ${raw_parse_result.nb_errors} fouten gevonden`,
            project_parsing_failed: ({parse_result}: {parse_result: any}) => `Verwerken van het bestand niet geslaagd. ${parse_result.nb_errors} fouten gevonden`,
            profile_not_found: () => `Het gezochte profiel werd niet gevonden`,
            project_file_not_found: () => `Het gezochte project-bestand werd niet gevonden`,
            invalid_project_type: ({project_type}: {project_type: string}) => `Het project type ${project_type} is ongeldig`,
            invalid_coordinate: ({value, attribute}: {value: string, attribute: string}) => `De ${attribute}-waarde van de coördinaat heeft ongeldige waarde ${value}`,
            invalid_line_code: ({line_code}: {line_code: string}) => `Ongeldige lijncode ${line_code}`,
            invalid_nb_elements: ({line, expected}: {line: string, expected: number}) => `Lijn ${line} kon niet worden gelezen. ${expected} aantal elementen verwacht`,
            record_data_types: ({line, fields}: {line: any, fields: Array<string>}) => `Fouten gevonden in velden ${fields} in lijn met nummer ${line.point_number}`,
            invalid_line_format: ({line}: {line: string}) => `Ongeldig formaat voor lijn ${line}`,
            no_matching_profile_record: ({object_code}: {object_code: string}) => `Geen bijhorende object code gevonden in profiel voor object_code ${object_code}.`,
            invalid_gps_record: ({line}: {line: string}) => `Lijn ${line} uit het GPS-bestand kon niet worden gelezen`,
            cannot_convert: () => `Project kon niet worden geconverteerd naar GPS-coördinatenstelsel`,
            missing_gps_point: ({point_code}: {point_code: string}) => `Geen matching GPS-punt voor basispunt ${point_code}`,
            parallel_to_none: ({project_record}: {project_record: any}) => `Lijn met nummer ${project_record.point_number} vraagt om parallel object, maar dit werd niet gevonden`,
            symbol_not_found: ({symbol_name}: {symbol_name: string}) => `Symbool ${symbol_name} niet gevonden in symbolenbestand`,
            symbols_file_not_found: ({symbols_file_name}: {symbols_file_name: string}) => `Symbolenbestand ${symbols_file_name} niet gevonden`,
            invalid_helmert: () => "Er zijn minstens 2 punten nodig om de Helmert transformatie te berekenen",
            unknown_parse_exception: () => "De lijn kon niet worden verwerkt. Controleer de tekst",
            unknown: () => "Oeps! Er ging iets mis. Probeer het later opnieuw",
            object_drawing_failed: ({point_numbers, line_codes}: {point_numbers: Array<string>, line_codes: Array<number>}) => `Object met puntnummers ${point_numbers} kon niet worden getekend. Dit kan komen door een ongeldige lijncode-sequentie. Lijncodes: ${line_codes}`,
            symbol_drawing_failed: ({symbol}: {symbol: string}) => `Symbool ${symbol} kon niet worden getekend, omdat het niet bekend was in het profiel. Controleer het DWG en OBJ bestand.`,
            no_second_arc_point: () => "Geen tweede bochtpunt gevonden voor lijncode 3",
            profile_parsing_failed: ({rows}: {rows: Array<string>}) => `OBJ-bestand kon niet worden verwerkt voor rijen: ${rows}`,
            invalid_obj_format: () => "Het OBJ-bestand heeft een ongeldig formaat. Controleer de aanhalingstekens",
            gps_content_parsing_failed: () => "Het bijgevoegde GPS-coördinatenbestand kon niet worden verwerkt",
            data_point_processing_failed: ({point_number, line_code}: {point_number: string, line_code: string}) => `Punt met nummer ${point_number} en lijncode ${line_code} kon niet worden verwerkt`,
            excentricity_processing_failed: ({point_number}: {point_number: string}) => `Excentriciteit van punt met nummer ${point_number} kon niet worden verwerkt`,
            no_referencing_object: ({point_number, line_code}: {point_number: string, line_code: string}) => `Punt met nummer ${point_number} en lijncode ${line_code} kon niet aan een object worden gelinkt`,
            conversion_failed: () => `Conversies konden niet worden berekend. Controleer de basispunten van het bestand`
        }
    }
}

export default nl;