import ScrollToTop from './scrollToTop';
import { stringAvatar, stringToColor} from './stringAvatar';

export async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export function pad(nb: number, size: number) {
    var num: string = nb.toString();
    while (num.length < size) num = "0" + num;
    return num;
};

export function formatTimestamp(unixSeconds: number) {
    const date = new Date(unixSeconds);

    const years = date.getFullYear();
    const months = pad(date.getMonth() + 1, 2);
    const days = pad(date.getDate(), 2);
    const hours = pad(date.getHours(), 2);
    const minutes = pad(date.getMinutes(), 2);
    const seconds = pad(date.getSeconds(), 2);

    return days + "/" + months + "/" + years + " " + hours + ':' + minutes + ':' + seconds;
};

export function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export function removeItem(array: Array<any>, item: any) {
    var i = array.length;

    while (i--) {
        if (array[i] === item) {
            array.splice(array.indexOf(item), 1);
        }
    }
    return array;
};

export function roundNumber(x: number, decimals: number) {
    return Math.round(10**decimals * x) / (10**decimals)
}

export function hash(s: string) {
    const utf8 = new TextEncoder().encode(s);
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
      return hashHex;
    });
}

export {
    ScrollToTop,
    stringAvatar,
    stringToColor,
};