import React from 'react';

import { Route, HashRouter as Router, Routes, } from 'react-router-dom'
import { ScrollToTop } from '../utils';
import { IRoute } from './types';
import routeConfigs from "./routes";

const MyRouter = () => {
    const routes = RouteConfigs(routeConfigs)

    return <Router>
        <ScrollToTop>
            <Routes>{routes}</Routes>
        </ScrollToTop>
    </Router>

}

const RouteConfigs = (routes: Array<IRoute>) => {
    return routes.map((route) => (
        <Route
            key={route.path}
            path={route.path}
            element={route.component}
        />))
}

export default MyRouter;