import { useState, useContext } from "react";
import { useRecoilState } from "recoil";
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Tooltip, IconButton, Button } from "@mui/material";
import { useLabel } from "../../../../hooks";
import { activeProjectState, projectListState } from "../../../../state/project";
import { APIStateContext } from "../../../../state/context";
import { Project } from "../../../../types/project";
import { formatTimestamp } from "../../../../utils";
import FolderIcon from '@mui/icons-material/Folder';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "../../../../components";

const RefreshButton = () => {
    const [refreshing, setRefreshing] = useState(false);
    const refreshLabel = useLabel("labels.refresh")
    const api = useContext(APIStateContext);

    return <Box sx={{ 
                width: "100%", 
                paddingRight: 8,
                paddingBottom: 2,
                justifyContent: "flex-end",
                alignItems: "flex-end",
                display: "flex"
            }}>
                {refreshing ? <Box><CircularProgress size={24}/></Box> : <></>}
                <Box>
                    <Tooltip title={refreshLabel}>
                        <IconButton onClick={() => {
                            setRefreshing(true);
                            api.refreshProjects().then(() => {
                                setRefreshing(false);
                            })
                        }} >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
};

const TableHeader = () => {
    const projectNameHeader = useLabel("existingProjects.name");
    const projectTypeHeader = useLabel("existingProjects.projectType");
    const projectCreationTimestampHeader = useLabel("existingProjects.creationTimestamp");
    const projectUpdateTimestampHeader = useLabel("existingProjects.updateTimestamp");

    return <TableHead>
        <TableRow>
            <TableCell></TableCell>
            <TableCell>{projectNameHeader}</TableCell>
            <TableCell>{projectTypeHeader}</TableCell>
            <TableCell>{projectCreationTimestampHeader}</TableCell>
            <TableCell>{projectUpdateTimestampHeader}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    </TableHead>
};

const DeleteIconButton = (props: {onDelete: any}) => {
    const tooltip = useLabel("labels.delete");
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    return <>
        <Tooltip title={tooltip}>
            <Button onClick={(event: any) => {event.stopPropagation(); setOpenDeleteConfirmation(true);}} 
                variant="outlined"
                sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                color="error"
            >
                <DeleteIcon fontSize="small" />
            </Button> 
        </Tooltip>
        <ConfirmationDialog 
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            onConfirm={props.onDelete}
            textLabelKey="labels.confirmDelete"
        />
    </>
};

const ProjectRow = (props: { project: Project }) => {
    const [_, setActiveProject] = useRecoilState(activeProjectState);
    const projectTypeValue = useLabel("existingProjects.projectTypeMappings." + props.project.project_type);
    const api = useContext(APIStateContext);
    const navigate = useNavigate();
    const onDelete = (event: any) => {
        event.stopPropagation();
        api.deleteProject(props.project.id);
    };
    
    const onLoad = async (event: any) => {
        await api.loadProject(props.project.id);
        navigate("/dashboard/modify-data");
    };

    return <TableRow hover onClick={onLoad} sx={{ cursor: "pointer"}}>
        <TableCell><FolderIcon sx={{ color: "#ebd834" }}/></TableCell>
        <TableCell>{props.project.name}</TableCell>
        <TableCell>{projectTypeValue}</TableCell>
        <TableCell>{formatTimestamp(props.project.creation_timestamp)}</TableCell>
        <TableCell>{formatTimestamp(props.project.update_timestamp)}</TableCell>
        <TableCell><DeleteIconButton onDelete={onDelete}/></TableCell>
    </TableRow>
};

const TableItems = (props: {projects: Array<Project>}) => {
    return <TableBody>{props.projects.slice().sort((obj1: Project, obj2: Project) => {
        if (obj1.creation_timestamp < obj2.creation_timestamp) {
            return 1;
        } else {
            return -1;
        }
    }).map((project: Project) => <ProjectRow project={project} key={project.id} />)}</TableBody>
};

const ExistingProjects = (props: any) => {
    const [projectList, setProjectList] = useRecoilState(projectListState);
    return <Box sx={{ margin: 8 }}>
        <RefreshButton />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: "80%" }} >
                <TableHeader />
                <TableItems projects={projectList.projects}/>
            </Table>
        </TableContainer>
    </Box>
};

export default ExistingProjects;
