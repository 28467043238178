export enum Role {
    appAdmin = "APP_ADMIN",
    companyAdmin = "COMPANY_ADMIN",
    companyUser = "COMPANY_USER",
    onHold = "ON_HOLD"
}

export interface AuthUser {
    name: string;
    id: string;
    role: Role;
}

export interface Company {
    name: string;
    id: string;
}
