import { useRecoilState } from "recoil";
import { activeProjectState, useIsActiveProjectSelected } from "../../../../state/project";
import { useLabel } from "../../../../hooks";
import { Box, Button, Typography, Paper } from "@mui/material";
import { FileStatus, ProjectFile, ProjectType, SelectedFile } from "../../../../types/project";
import { DrawSettingsInput, FileUpload, NameInput, ProfileInput, TypeInput } from "../new-project/NewProject";
import { defaultDrawSettings } from "../../../../data/defaultValues";
import { useContext, useState } from "react";
import { APIStateContext } from "../../../../state/context";
import Container from "../../../../components/PaperContainer";

const Placeholder = () => {
    const label = useLabel("labels.noActiveProjectSelected")
    return <Paper elevation={3} sx={{ padding: 4, margin: 8 }}>
        <Typography>{label}</Typography>
    </Paper>
};

const UpdateButton = (props: {onClick: any}) => {
    const [disabled, setDisabled] = useState(false);
    const label = useLabel("projectConfig.update");
    return <Box mt={4}>
        <Button
            disabled={disabled}
            onClick={() => {
                setDisabled(true); 
                props.onClick().then(setDisabled(false));
            }} 
            variant="contained">{label}</Button>
    </Box>
}

function makeSelectedFile(projectFile: ProjectFile | null | undefined): SelectedFile | null | undefined {
    if (projectFile !== undefined && projectFile !== null) {
        return {
            name: projectFile.name,
            hash: projectFile.id,
            url: "",
            status: FileStatus.UPLOADED
        }
    }
    return null;
}

const Modify = (props: any) => {
    const [activeProject, setActiveProject] = useRecoilState(activeProjectState);
    const [name, setProjectName] = useState(activeProject.project?.name);
    const [projectType, setProjectType] = useState(activeProject.project?.project_type);
    const [profileID, setProfileID] = useState(activeProject.project?.profile_id);
    const [drawSettings, setDrawSettings] = useState(activeProject.project?.draw_settings);
    const [csvFile, setCsvFile] = useState(makeSelectedFile(activeProject.project?.csv_file));
    const [gpsFile, setGpsFile] = useState(makeSelectedFile(activeProject.project?.gps_file));
    const [tmfFile, setTmfFile] = useState(makeSelectedFile(activeProject.project?.tmf_file));

    const fileUploadProps = {
        csvFile, setCsvFile, tmfFile, setTmfFile, gpsFile, setGpsFile, projectType: (projectType ?? ProjectType.GPS)
    };
    const api = useContext(APIStateContext);

    return useIsActiveProjectSelected() ? <Container>
        <NameInput name={name ?? ""} setName={setProjectName} />
        <TypeInput disabled type={projectType ?? ProjectType.GPS} setType={setProjectType} />
        <FileUpload {...fileUploadProps} />
        <ProfileInput profileID={profileID ?? ""} setProfileID={setProfileID}/>
        <DrawSettingsInput drawSettings={drawSettings ?? defaultDrawSettings} setDrawSettings={setDrawSettings}/>
        <UpdateButton onClick={() => api.updateProject(
            activeProject.project?.id,
            name,
            profileID,
            drawSettings,
            csvFile,
            tmfFile,
            gpsFile
        )}/>
    </Container> : <Placeholder />
}

export default Modify;
