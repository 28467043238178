import { atom, RecoilState, selector, useRecoilValue, useRecoilState } from "recoil";
import { BasePointOutput, Conversion, ParseResult, Project } from "../types/project";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export interface ProjectListState {
    projects: Array<Project>
};

const defaultProjectListState: ProjectListState = {
    projects: []
};

export const projectListState: RecoilState<ProjectListState> = atom({
    key: "projectListState",
    default: defaultProjectListState
});

export const selectProjects = selector({
    key: "selectProjectList",
    get: ({ get }) => {
        return get(projectListState).projects;
    }
});

export const useProjectList = () => useRecoilValue(selectProjects);

export const useAddProject = (project: Project) => {
    const [projectList, setProjectList] = useRecoilState(projectListState);
    const newProjects = [project, ...projectList.projects];
    setProjectList({projects: newProjects});
};

export interface ActiveProjectState {
    project: Project | null
    parse_result: ParseResult | null
    conversions: Array<Conversion> | null
    base_points?: Array<BasePointOutput> | null
};

const defaultActiveProjectState: ActiveProjectState = {
    project: null,
    parse_result: null,
    conversions: null
};

export const activeProjectState: RecoilState<ActiveProjectState> = atom({
    key: "activeProject",
    default: defaultActiveProjectState,
    effects_UNSTABLE: [persistAtom]
});

const selectIsActiveProjectSelected = selector({
    key: "selectIsActiveProjectSelected",
    get: ({ get }) => {
        return get(activeProjectState).project !== null;
    }
});

const selectActiveProject = selector({
    key: "selectActiveProject",
    get: ({ get }) => {
        return get(activeProjectState).project;
    }
});

const selectParseResult = selector({
    key: "selectParseResult",
    get: ({ get }) => {
        return get(activeProjectState).parse_result;
    }
})

export const useIsActiveProjectSelected = () => useRecoilValue(selectIsActiveProjectSelected);
export const useActiveProject = () => useRecoilValue(selectActiveProject);
export const useParseResult = () => useRecoilValue(selectParseResult);
