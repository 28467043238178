import { atom, RecoilState, selector, useRecoilValue } from "recoil";
import { mockAppInfoResponse } from "../data/mockData";

interface AppInfo {
    author: string
    version: string
    contact: string
};

const defaultAppInfo: AppInfo = mockAppInfoResponse;

const appInfoState: RecoilState<AppInfo> = atom({
    key: "appInfo",
    default: defaultAppInfo
});

const selectAppInfo = selector({
    key: "selectAppInfo",
    get: ({ get }) => {
        const appInfo = get(appInfoState);
        return appInfo;
    }
});

const useAppInfo = () => useRecoilValue(selectAppInfo);

export { appInfoState, useAppInfo }