import { useMatch } from "react-router-dom"
import { ProfileHeader } from "../../../../../types/profile";
import { Box } from "@mui/material";
import EditProfileHeader from "./EditProfileHeader";
import EditProfileRecords from "./EditProfileRecords";
import { useProfiles } from "../../../../../state/profile";


const EditProfile = (props: any) => {
    const match = useMatch("/dashboard/profiles/:profileID")
    const profileID = match?.params.profileID
    var profiles: Array<ProfileHeader | undefined> = useProfiles();
    profiles = profiles.filter((profile: ProfileHeader | undefined) => profile?.id === profileID)
    const profile = profiles.concat([undefined])[0]

    return <Box>
        {profile !== undefined ? (
            <>
                <EditProfileHeader profile={profile} />
                <EditProfileRecords profile={profile} />
            </>
         ) : <></>}

    </Box>
};

export default EditProfile;
