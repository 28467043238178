import { Box, Button, CircularProgress, List, ListItem, ListItemButton, ListItemIcon, Paper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { DownloadableFile, DrawingStatus, InitiateDrawingResponse } from "../../../../api/responses/project";
import { PaperContainer } from "../../../../components";
import { useLabel, useLanguage } from "../../../../hooks";
import { APIStateContext, IAPIStateContext } from "../../../../state/context";
import DownloadIcon from '@mui/icons-material/Download';
import { useActiveProject } from "../../../../state/project";
import { Project } from "../../../../types/project";
import Preview, { Spinner } from "./Preview";
import { sleep } from "../../../../utils";

async function onDrawingStarted(
    response: InitiateDrawingResponse,
    setDrawing: any,
    api: IAPIStateContext,
    setDXF: any,
    setDWG: any,
    setWarnings: any
) {
    var status = {status: DrawingStatus.BUSY, dxf: null, dwg: null, error: null, warnings: []};
    var counter = 0;
    while (status.status === DrawingStatus.BUSY) {
        status = await api.getDrawingStatus(response.project_id);

        await sleep(3000);
        if (status.status === DrawingStatus.COMPLETED) {
            setDXF(status.dxf);
            setDWG(status.dwg);
            setDrawing(false);
            setWarnings(status.warnings);
        } else if (status.status === DrawingStatus.FAILED) {
            setDrawing(false);
        } else {
            counter++;
            if (counter > 100) {
                setDrawing(false);
                alert("Timeout");
                break;
            }
        }
    }
}
const Draw = (props: any) => {
    const [drawing, setDrawing] = useState(false);
    const [dxf, setDXF] = useState<DownloadableFile | null>(null);
    const [dwg, setDWG] = useState<DownloadableFile | null>(null);
    const [warnings, setWarnings] = useState<Array<any>>([]);
    const activeProject = useActiveProject();
    const api = useContext(APIStateContext);
    const draw = () => { 
        setDrawing(true);
        return api.initiateDrawing(activeProject?.id, false).then((response: InitiateDrawingResponse) => onDrawingStarted(
            response, setDrawing, api, setDXF, setDWG, setWarnings
        ))}; 
    const preview = () => {
        setDrawing(true);
        return api.initiateDrawing(activeProject?.id, true).then((response: InitiateDrawingResponse) => onDrawingStarted(
            response, setDrawing, api, setDXF, setDWG, setWarnings
        ))};
    const [previewOpen, setPreviewOpen] = useState(false);

    if (activeProject !== null && activeProject !== undefined) {
        return <>
            <PaperContainer>
                <Box sx={{pt: 4, justifyContent: "space-between", flexDirection: "row", display: "flex"}}>
                    <Box sx={{ display: "inline-block"}}>
                        <Box sx={{ 
                            pl: 4,
                            pr: 4,
                            display: "flex", 
                            flexDirection: "row", 
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                        }}>
                            <DrawButton draw={draw} />
                            <PreviewButton preview={preview} setOpen={setPreviewOpen}/>
                        </Box>
                    </Box>
                <Box sx={{display: "inline-block"}}>
                    {drawing ? <CircularProgress /> : <></>}
                </Box>
            </Box>
                {
                    dxf !== null && dwg !== null ? (
                        <>
                            <List>
                                <FileDownload file={dxf} api={api}/>
                                <FileDownload file={dwg} api={api}/>
                            </List>
                            <Warnings warnings={warnings} />
                        </>

                    ) : (
                        <></>
                    )
                }


            </PaperContainer>
            <Preview open={previewOpen} setOpen={setPreviewOpen} busy={drawing} setBusy={setDrawing} dxf={dxf?.url ?? ""}/>
        </>
    } else {
        return <Placeholder />
    }

};

const FileDownload = (props: {file: DownloadableFile, api: IAPIStateContext}) => {
    return <ListItem>
        <ListItemButton onClick={() => props.api.downloadFile(props.file.name, props.file.url)}>
            <ListItemIcon>
                <DownloadIcon />
            </ListItemIcon>
            <Typography>{props.file.name}</Typography>
        </ListItemButton>
    </ListItem>
};

const Placeholder = () => {
    const label = useLabel("labels.noActiveProjectSelected")
    return <Paper elevation={3} sx={{ padding: 4, margin: 8 }}>
        <Typography>{label}</Typography>
    </Paper>
};

const DrawButton = (props: {draw: any}) => {
    const drawLabel = useLabel("draw.draw");

    return <Box sx={{p: 1}}>
        <Button variant="outlined" onClick={props.draw} >
            {drawLabel}
        </Button>
    </Box>
};

const PreviewButton = (props: {preview: any, setOpen: any}) => {
    const drawLabel = useLabel("draw.preview");

    return <Box sx={{p: 1}}>
        <Button variant="outlined" onClick={() => {
            props.preview();
            props.setOpen(true);
        }} >
            {drawLabel}
        </Button>
    </Box>
};

const Warnings = (props: {warnings: Array<any>}) => {
    const language = useLanguage();
    const getWarningText = (warning: any) => {
        return (language.error.server as any)[warning.error_code](warning.error_params);
    }
    return <>
        {props.warnings.map((warning: any, index: number) => <Typography key={index.toString()} sx={{padding: 1}}>{
            getWarningText(warning)
        }</Typography>)}
    </>
}
export default Draw;
