import ChangePassword from './ChangePassword';
import UserList from './UserList';

const Settings = (props: any) => {
    return <>
        <UserList />
        <ChangePassword />
    </>
};

export default Settings;