import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { 
  AppBar, 
  Box, 
  IconButton, 
  Toolbar, 
  Tooltip, 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useIsCompanyAdmin } from '../../../hooks/user';
import { useLabel } from '../../../hooks';
import UserAvatar from "./UserAvatar";


const DashboardNavbarRoot = styled(AppBar)(({ theme }: any) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

const Navbar = (props: { children: any }) => {
  return <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}>
          <Toolbar
            disableGutters
            sx={{
              minHeight: 64,
              left: 0,
              px: 2
            }}
          >
            {props.children}
          </Toolbar>
        </DashboardNavbarRoot>
}

const MenuButton = (props: { onSidebarOpen: any }) => <IconButton
  onClick={props.onSidebarOpen}
  sx={{
    display: {
      xs: 'inline-flex',
      lg: 'none'
    }
  }}
  >
    <MenuIcon fontSize="small" />
  </IconButton>

const SettingsButton = () => {
  const label = useLabel("labels.settings");

  return <Tooltip title={label}>
    <Link to="/settings">
      <IconButton sx={{ mr: 1 }}>
        <SettingsOutlinedIcon />
      </IconButton>
    </Link>
  </Tooltip>
}

const DashboardNavbar = (props: any) => {
  const { onSidebarOpen } = props;

  return (
      <Navbar>
          <MenuButton onSidebarOpen={onSidebarOpen} />
          <Box sx={{ flexGrow: 1 }} />
          <SettingsButton />
          <UserAvatar />
      </Navbar>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};

export default DashboardNavbar;
