import { Typography, Box } from "@mui/material";
import { useLabel } from "../../hooks/language";

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const PanelTitle = (props: {title: string}) => {
    const label = capitalizeFirstLetter(useLabel(props.title));
    return <Box sx={{ pl: 8 }}><Typography variant="h2">{label}</Typography></Box>
}

export default PanelTitle;